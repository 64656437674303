import { BatchPoint, DEFAULT_ZOOM_LEVEL, GRENOBLE_COORDINATES } from "helpers/geo";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BatchesMapContextInterface {
    defaultCenter: google.maps.LatLngLiteral;
    defaultBounds: google.maps.LatLngBoundsLiteral | undefined;
    defaultZoom: number;
    points: BatchPoint[];
};

const initialState: BatchesMapContextInterface = {
    defaultCenter: GRENOBLE_COORDINATES,
    defaultBounds: {
        north: 0,
        south: 0,
        east: 0,
        west: 0,
    },
    defaultZoom: DEFAULT_ZOOM_LEVEL,
    points: [],
};

export const batchesMapSlice = createSlice({
    name: 'batchesMap',
    initialState: initialState,
    reducers: {
        removeMapPoints: (state) => {
            state.points = [];
        },
        setMapPoints: (state, { payload: { center, bounds, points } }: PayloadAction<{ center: google.maps.LatLngLiteral, bounds: google.maps.LatLngBoundsLiteral, points: BatchPoint[] }>) => {
            state.defaultCenter = center;
            state.defaultBounds = bounds;
            state.points = points;
        },
        setMapBounds: (state, { payload: { center, bounds } }: PayloadAction<{ center: google.maps.LatLngLiteral, bounds: google.maps.LatLngBoundsLiteral }>) => {
            state.defaultCenter = center;
            state.defaultBounds = bounds;
        },
        setCenterAndZoom: (state, { payload: { center, zoom } }: PayloadAction<{ center: google.maps.LatLngLiteral, zoom: number }>) => {
            state.defaultCenter = center;
            state.defaultZoom = zoom;
        },
        resetMapBounds: (state) => {
            state.defaultBounds = undefined;
        },
    },
});

export const BatchesMapActions = batchesMapSlice.actions;

const BatchesMapReducer = batchesMapSlice.reducer;

export default BatchesMapReducer;