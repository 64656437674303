import { useTheme } from "@mui/material"
import { Place } from "@mui/icons-material";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { MISSION_ADDRESS_COLOR_MAPPING, MissionAddressStatus } from "models/Missions";

type MarkerProps = {
    addressKey: string;
    lat: number;
    lng: number;
    status: MissionAddressStatus;
    highlighted?: boolean;
    onClick: (addressKey: string) => void;
}

const NORMAL_MARKER_SIZE = 40;
const HIGHLIGHTED_MARKER_SIZE = 64;

export default function MissionMapMarker({ addressKey, lat, lng, status, highlighted, onClick }: MarkerProps) {
    const theme = useTheme();

    // put highlighted to the front, selected to add second, not added behind
    let zIndex = 2;
    if (status === MissionAddressStatus.NOT_ADDED) zIndex = 1;
    if (highlighted) zIndex = 3;

    return (
        <AdvancedMarker 
            onClick={() => onClick(addressKey)} 
            position={{ lat, lng }}
            zIndex={zIndex}
            >
            <Place 
                color={MISSION_ADDRESS_COLOR_MAPPING[status]} 
                sx={{ 
                    fontSize: highlighted ? HIGHLIGHTED_MARKER_SIZE : NORMAL_MARKER_SIZE,
                    stroke: status === MissionAddressStatus.SELECTED_TO_ADD ? "#fff" : theme.palette.secondary.dark,
                    strokeWidth: status === MissionAddressStatus.SELECTED_TO_ADD ? 1 : .25,
                    filter: "drop-shadow(1px 1px 0px rgba(0,0,0,.25))"
                }} 
                />
        </AdvancedMarker>
    )
}