import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { useAppSelector, useAppDispatch, usePartnerID } from 'store/hooks';
import { selectAllCollaborators } from 'store/reducers/collaborators/list';
import { UserData, UserRole } from 'models/User';
import Collaborator from 'models/Collaborator';
import MissionAddressesMethods from 'controllers/sensitization_addresses';
import { People } from '@mui/icons-material';
import DropdownMenu from 'components/_include/DropdownMenu/DropdownMenu';

interface AmbassadorsDropdownProps {
    disabled: boolean;
    selectedAddressIds: string[];
    missionID: string;
}

const convertToUserData = (collaborator: Collaborator, partnerID: string): UserData => ({
    name: `${collaborator.firstName} ${collaborator.lastName}`,
    role: collaborator.role,
    partnerID: partnerID,
    email: collaborator.email,
});

const AmbassadorsDropdown: React.FC<AmbassadorsDropdownProps> = ({ disabled, selectedAddressIds, missionID }) => {
    const { t } = useTranslation([Namespace.MISSIONS]);
    const dispatch = useAppDispatch();
    const partnerID = usePartnerID();

    const collaborators = useAppSelector(selectAllCollaborators);
    const loading = useAppSelector((state) => state.missions.missionAddresses.loading);

    // State to control Autocomplete
    const [inputValue, setInputValue] = useState<string>('');

    // Filter collaborators with role ambassador and convert to UserData type
    const ambassadors: UserData[] = useMemo(() => {
        if (!partnerID) return [];
        return collaborators
            .filter((collaborator): collaborator is Collaborator => collaborator?.role === UserRole.AMBASSADOR && collaborator.email !== undefined)
            .map(collaborator => convertToUserData(collaborator, partnerID));
    }, [collaborators, partnerID]);

    const handleAssign = (selectedValue: string | null) => {
        const selectedAmbassador = ambassadors.find(a => a.email === selectedValue);
        if (selectedAmbassador && selectedAddressIds.length > 0) {
            dispatch(MissionAddressesMethods.assignAmbassadorToAddresses(
                partnerID!,
                missionID!,
                selectedAddressIds,
                selectedAmbassador.email,
                selectedAmbassador.name,
            ));
            setInputValue('');
        }
    };

    const inputLabel = t('select_ambassador', { ns: Namespace.MISSIONS });

    return (
        <Box display="flex" alignItems="center">
            <DropdownMenu
                id="assign-ambassador-menu"
                title={inputLabel}
                values={ambassadors.map(a => ({ label: a.name, value: a.email }))}
                defaultValue={inputValue}
                startIcon={<People />}
                onChange={handleAssign}
                loading={loading}
                disabled={loading || !selectedAddressIds.length}
                />
        </Box>
    );
};

export default AmbassadorsDropdown;
