export enum DiscussedTopic {
    GARBAGE_BAGS = 'garbage_bags',
    BULKY_ITEMS = 'bulky_items',
    GLASS = 'glass',
    TEXTILES = 'textiles',
    ILLEGAL_DUMPING = 'illegal_dumping',
    DAYS_OF_COLLECTION = 'days_of_collection',
    SORTING_GUIDE = 'sorting_guide',
    DEDICATED_BAG = 'dedicated_bag',
}

export const TOPICS = Object.values(DiscussedTopic);

export enum CommunicationMethod {
    PHONE = 'phone',
    DOOR_TO_DOOR = 'door_to_door',
    LEAFLET = 'leaflet',
}