import { ThemeProvider, } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { APIProvider } from '@vis.gl/react-google-maps';
import Pages from './components/Pages';
import GlobalSnackbar from './components/_include/GlobalSnackbar';
import theme from './constants/theme';
import store from './store/store';

const FIREBASE_CONFIG = require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`);

function App() {

  return (
    <APIProvider apiKey={FIREBASE_CONFIG.apiKey} libraries={['geometry']}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Pages />
          <GlobalSnackbar />
        </ThemeProvider>
      </Provider>
      </APIProvider>
  );
}


export default App;
