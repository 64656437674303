import { useAppSelector } from "store/hooks";
import { DangerLevel } from "constants/stats";
import MapMarker from "components/_include/Maps/MapMarker";

type BatchMarkerProps = {
    lat: number;
    lng: number;
    batchID: string;
    dangerLevel: DangerLevel;
    onClick: (batchID: string) => void;
    mapInstance: google.maps.Map | null;
    panoramaInstance: google.maps.StreetViewPanorama | null;
};

export default function BatchMarker(props: BatchMarkerProps) {
    const { lat, lng, batchID, dangerLevel, onClick, mapInstance, panoramaInstance } = props;
    const selected = useAppSelector(state => state.batches.list.selectedBatch?.ID === batchID);
    const hovered = useAppSelector(state => state.batches.list.hoveredBatchID === batchID);

    return (
        <MapMarker
            lat={lat}
            lng={lng}
            id={batchID}
            dangerLevel={dangerLevel}
            selected={selected}
            hovered={hovered}
            onClick={onClick}
            mapInstance={mapInstance}
            panoramaInstance={panoramaInstance}
            isAddressMarker={false}
        />
    );
}
