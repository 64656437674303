import { Box } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { DEFAULT_ZOOM_LEVEL, GRENOBLE_COORDINATES, areSameCoordinates } from "helpers/geo";
import { useAppDispatch, useAppSelector, useMapCamera, usePartnerID } from "store/hooks";
import PartnersController from "controllers/partners";
import SaveDefaultMapBoundsButton from "./SaveDefaultMapBoundsButton";
import { MAP_HEIGHT } from "components/ManageCollections/CollectionDetail/BatchesMap/BatchesMapWrapper";
import { MapCameraChangedEvent } from "@vis.gl/react-google-maps";
import { MapWrapper } from "components/_include/Maps/MapWrapper";

export default function DefaultMapBoundsWrapper() {

    const mapWrapperRef = useRef<HTMLDivElement>(null);

    const [initialCameraSet, setInitialCameraSet] = useState(false);

    const defaultMapViewport = useAppSelector((state) => state.partners.selected.data?.defaultMapViewport);
    
    // Initialize with fallback values
    const INITIAL_CAMERA = {
        center: GRENOBLE_COORDINATES,
        zoom: DEFAULT_ZOOM_LEVEL
    };

    const { cameraProps, setCameraProps } = useMapCamera(INITIAL_CAMERA);

    useEffect(() => {
        if (defaultMapViewport && !initialCameraSet) {
            setCameraProps(defaultMapViewport);
            setInitialCameraSet(true);
        }
    }, [defaultMapViewport, initialCameraSet, setCameraProps]);

    const [saveButtonVisible, setSaveButtonVisible] = useState(false);

    const partnerID = usePartnerID();
    const dispatch = useAppDispatch();

    const onSaveClicked = () => {
        if (partnerID) {
            dispatch(PartnersController.update(partnerID, {
                defaultMapViewport: cameraProps
            }))
                .then(success => {
                    if (success) setSaveButtonVisible(false); // hide button after save
                });
        }
    }

    const handleCameraChange = useCallback((ev: MapCameraChangedEvent) => {
        if (!areSameCoordinates(cameraProps.center, ev.detail.center, 8) || cameraProps.zoom !== ev.detail.zoom) { // user has changed map viewport
            // NB: we check that it's not the first change when map is set (coordinates may have tiny differences)
            setSaveButtonVisible(true); // 
        }
        const { center, zoom } = ev.detail;

        setCameraProps({ center: center, zoom });
    }, [cameraProps.center, cameraProps.zoom, setCameraProps]);


    return (
        <Box
            ref={mapWrapperRef}
            id="batches-map"
            width="100%"
            height={MAP_HEIGHT}
            mb={4}
            borderRadius={4}
            border={1}
            borderColor="rgba(0,0,0,0.12)"
            overflow="hidden"
            position="relative"
        >
            <MapWrapper
                mapId="defaultMap"
                initialCamera={cameraProps}
                onCameraChanged={handleCameraChange}
                mapTypeControlOptions={{
                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: google.maps.ControlPosition.BOTTOM_CENTER,
                    mapTypeIds: [
                        google.maps.MapTypeId.ROADMAP,
                        google.maps.MapTypeId.SATELLITE,
                    ],
                }}
            />
            <SaveDefaultMapBoundsButton
                visible={saveButtonVisible}
                onClick={onSaveClicked}
            />
        </Box>
    );
}