import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';

type TopicsWithCheckboxesProps = {
    topics: string[];
    selectedTopics: string[];
};

const TopicsWithCheckboxes: React.FC<TopicsWithCheckboxesProps> = ({ topics, selectedTopics }) => {
    const { t } = useTranslation([Namespace.MISSION_REPORT]);

    return (
        <FormControl component="fieldset" sx={{ mb: 2 }}>
            <Typography variant="subtitle1">{t('topics.question', { ns: Namespace.MISSION_REPORT })}</Typography>
            <FormGroup>
                <Grid container>
                    {topics.map((topic) => (
                        <Grid item xs={6} sm={6} md={4} key={topic}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedTopics.includes(topic)}
                                        disabled={!selectedTopics.includes(topic)}
                                    />
                                }
                                label={t(`topics.${topic}`, { ns: Namespace.MISSION_REPORT })}
                            />
                        </Grid>
                    ))}
                </Grid>
            </FormGroup>
        </FormControl>
    );
};

export default TopicsWithCheckboxes;
