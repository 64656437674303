import { darken } from "@mui/material";
import { DangerLevel } from "constants/stats";
import { DANGER_COLORS } from "helpers/draw";

// Marker sizes and opacity thresholds
const MARKER_SIZES = {
    default: 12,
    hovered: 16,
    selected: 20,
    streetView: 100,
    streetViewSelected: 150,
  };
  
  // Global icon cache
  const iconCache: { [key: string]: google.maps.Icon } = {};
  
  /**
   * Generate and cache a custom Google Maps icon.
   * @param markerColor - The color of the marker.
   * @param markerSize - The size of the marker.
   * @param markerOpacity - The opacity of the marker.
   * @returns Cached or newly generated icon.
   */
  export const getCustomIcon = (markerColor: string, markerSize: number, markerOpacity: number): google.maps.Icon => {
    const cacheKey = `${markerColor}-${markerSize}-${markerOpacity}`;
  
    if (iconCache[cacheKey]) {
      return iconCache[cacheKey];
    }
  
    const pinSVG = `
      <svg xmlns="http://www.w3.org/2000/svg" width="${markerSize}" height="${markerSize}" viewBox="0 0 ${markerSize} ${markerSize}">
        <circle 
          cx="${markerSize / 2}" 
          cy="${markerSize / 2}" 
          r="${(markerSize / 2) - 2}" 
          fill="${markerColor}"
          stroke="${darken(markerColor, 0.2)}" 
          stroke-width="2" 
          fill-opacity="${markerOpacity}"
        />
      </svg>
    `;
  
    const icon: google.maps.Icon = {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(pinSVG)}`,
      scaledSize: new google.maps.Size(markerSize, markerSize),
      anchor: new google.maps.Point(markerSize / 2, markerSize / 2),
    };
  
    iconCache[cacheKey] = icon;
  
    return icon;
  };
  
// Pre-generate icons for all known combinations
export  const preGenerateIcons = () => {
    const DANGER_LEVELS = Object.values(DangerLevel);
    const OPACITIES = [1, 0.5, 0.1];
  
    DANGER_LEVELS.forEach((level) => {
      const color = DANGER_COLORS[level];
      [MARKER_SIZES.default, MARKER_SIZES.hovered, MARKER_SIZES.selected].forEach((size) => {
        OPACITIES.forEach((opacity) => {
          getCustomIcon(color, size, opacity);
        });
      });
    });
  };


/**
 * Get the z-index value for a map marker, based on its threat level.
 * Biggest threat (worst sorting) should appear on top
 */
export const getMarkerZIndex = (color: DangerLevel) => {
    switch (color) {
        case DangerLevel.NONE: return 90;
        case DangerLevel.LOW: return 91;
        case DangerLevel.MEDIUM: return 92;
        case DangerLevel.HIGH: return 93;
        case DangerLevel.VERY_HIGH: return 94;
    }
}  