import { Grid } from '@mui/material';
import AreaSelect from 'components/_include/Filters/AreaSelect';
import { getDefaultFiltersValues, DangerLevel } from 'constants/stats';
import BatchesController from 'controllers/batches';
import { Timestamp } from 'firebase/firestore';
import { DatesRangeType, getDatesFromDateSelectType, getStartAndEndFromURLParams, isSingleDay } from 'helpers/dates';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useSortingRules, usePartnerID } from 'store/hooks';
import { TrashType } from 'constants/trash';
import { CollectionType } from 'models/Collection';
import { SearchParam } from 'constants/urls';
import RouteSelect from 'components/_include/Filters/RouteSelect';
import DatesSelect from 'components/_include/Filters/DatesSelect';
import TimesRangeSelect from 'components/_include/Filters/TimeSelect/TimesRangeSelect';
import ErrorsSelect from 'components/_include/Filters/ErrorsSelect';
import ErrorsCountSelect from 'components/_include/Filters/ErrorsCountSelect';

function MissionAddressesFilters() {
    const partnerID = usePartnerID();

    const { displayedErrors } = useSortingRules();

    const wasteStream = useAppSelector(state => state.sortingRules.selectedWasteStream);

    const loading = useAppSelector(state => state.batches.sortingMap.loading);

    const dispatch = useAppDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const startTime = searchParams.get("startTime");
    const endTime = searchParams.get("endTime");
    const truckIDParam = searchParams.get(SearchParam.TRUCK_ID);
    const routeIDParam = searchParams.get(SearchParam.ROUTE_ID);
    const areaID = searchParams.get(SearchParam.AREA_ID);
    const errors = searchParams.getAll(SearchParam.SELECTED_ERRORS)
        .filter(e => displayedErrors
            .includes(e as TrashType)) as TrashType[];
    const flattenedErrors = errors.join("");
    const hiddenParam = searchParams.get("hidden");
    const errorsCountParam = searchParams.get(SearchParam.MIN_ERRORS_COUNT);
    const dateSelect = searchParams.get("dateSelect") as DatesRangeType;

    /** Set default filters values */
    useEffect(() => {
        if (!partnerID || !wasteStream) return; // wait until partner and sorting rules are loaded

        
        let needUpdate = false;

        const { errors: defaultErrors, } = getDefaultFiltersValues();
        const defaultDateSelect = DatesRangeType.THIS_WEEK;

        if (!searchParams.has(SearchParam.SELECTED_ERRORS)) {
            needUpdate = true;
            for (const error of defaultErrors) {
                searchParams.append(SearchParam.SELECTED_ERRORS, error);
            }
        }

        if (!searchParams.has("dateSelect")) { 
            needUpdate = true;
            searchParams.set("dateSelect", defaultDateSelect);
        }

        if (!searchParams.has("startDate") || !searchParams.has("endDate")) {
            needUpdate = true;
            const { start, end } = getDatesFromDateSelectType(dateSelect ?? defaultDateSelect);
            if (start) searchParams.set("startDate", start.format("YYYY-MM-DD"));
            if (end) searchParams.set("endDate", end.format("YYYY-MM-DD"));
        }

        if (needUpdate) {
            setSearchParams(searchParams);
        }
    }, [partnerID, wasteStream]);

    const singleDaySelected = useMemo(() => {
        if (!dateSelect) return false;
        return isSingleDay(dateSelect as DatesRangeType, moment(startDate), moment(endDate));
    }, [dateSelect, startDate, endDate]);

    /**
     * Format the list of Firestore filters to apply on the "List batches" request
     */
    const getFilters = (collectionType: CollectionType) => {
        let filters: Parameters<typeof BatchesController.list>[1] = [
            { fieldPath: "display", opStr: "==", value: true },
            { fieldPath: "verified", opStr: "==", value: true },
            { fieldPath: "collectionType", opStr: "==", value: collectionType },
        ];

        if (routeIDParam) {
            filters.push({ fieldPath: "routeID", opStr: "==", value: routeIDParam });
        }

        if (dateSelect) { // dates filter
            const { start, end } = getStartAndEndFromURLParams(dateSelect, startDate, endDate, singleDaySelected ? startTime : undefined, singleDaySelected ? endTime : undefined)

            if (start) {
                filters.push({
                    fieldPath: "timestamp",
                    opStr: ">=",
                    value: Timestamp.fromDate(start.toDate()),
                });
            }

            if (end) {
                filters.push({
                    fieldPath: "timestamp",
                    opStr: "<=",
                    value: Timestamp.fromDate(end.toDate()),
                });
            }
        }

        // area filter
        if (areaID) {
            filters.push({
                fieldPath: "areasIDs",
                opStr: "array-contains",
                value: areaID,
            });
        }

        return filters;
    }

    /**
     * Load matching batches every time a parameter changes
     */
    useEffect(() => {
        if (partnerID && wasteStream && dateSelect && startDate && endDate) {

            const filters = getFilters(wasteStream);

            // danger level filter
            let matchingDangerLevel: [string, DangerLevel] | undefined;
            if (errorsCountParam) {
                matchingDangerLevel = Object.entries(DangerLevel).filter(([key, _]) => key === errorsCountParam)[0];
            }

            dispatch(BatchesController.listPartnerBatchesForMap(partnerID, filters, errors, "sorting", matchingDangerLevel?.[1]));
        }
    }, [truckIDParam, wasteStream, startDate, endDate, startTime, endTime, areaID, hiddenParam, dateSelect, flattenedErrors, partnerID, errorsCountParam, routeIDParam]);

    return (
        <Grid
            container
            alignContent="flex-start"
            spacing={1}
        >
        <Grid item>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <DatesSelect
                            parentLoading={loading}
                        />
                    </Grid>

                    {singleDaySelected && (
                        <Grid item>
                            <TimesRangeSelect
                                parentLoading={loading}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Grid item>
                <AreaSelect
                    parentLoading={loading}
                />
            </Grid>
            <Grid item>
                <RouteSelect
                    parentLoading={loading}
                />
            </Grid>
            <Grid item>
                <ErrorsSelect
                    parentLoading={loading}
                />
            </Grid>
            <Grid item>
                <ErrorsCountSelect
                    parentLoading={loading}
                />
            </Grid>
        </Grid>
    );
}

export default MissionAddressesFilters;