import { Box, Grid, Stack } from '@mui/material';
import AreasMethods from 'controllers/areas';
import ActionButton from 'components/_include/Buttons/ActionButton';
import PartnerSelect from 'components/_include/Filters/PartnerSelect';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useIsAdmin, usePartnerID } from 'store/hooks';
import AddIcon from '@mui/icons-material/AddRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { SelectedAreaActions } from 'store/reducers/areas/selected';
import AreaNameDialog from './AreaNameDialog';
import { useSearchParams } from 'react-router-dom';
import AreasHelpSpeedDial from './AreasHelpSpeedDial/AreasHelpSpeedDial';
import OsmSearchBar from './OsmSearchBar';
import { selectPartnersIds } from 'store/reducers/partners/list';
import { SearchParam } from 'constants/urls';

export const SAVE_AREA_BUTTON_ID = "save-new-area-button";

function AreasControls() {
    const isAdmin = useIsAdmin();
    const hasSeveralPartners = useAppSelector(state => selectPartnersIds(state).length > 1);
    const partnerID = usePartnerID();

    const loading = useAppSelector(state => state.areas.list.loading);

    const selectedArea = useAppSelector(state => state.areas.selected.data);
    const selectedOsmAreaName = useAppSelector(state => state.areas.osm.selected?.name);
    const editing = useAppSelector(state => state.areas.selected.editing);
    const newPath = useAppSelector(state => state.areas.selected.newPath);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!partnerID) return;
        // load all areas
        dispatch(AreasMethods.list(partnerID, []))
            .then(areas => {
                if (!selectedArea) { // select default area if none selected yet
                    if (areas.length > 0) {
                        const defaultArea = areas.sort((a1, a2) => a1.name.localeCompare(a2.name))[0]; // first one alphabetically
                        dispatch(AreasMethods.select(defaultArea));

                        searchParams.set(SearchParam.AREA_ID, defaultArea.ID);
                        setSearchParams(searchParams);
                    }
                }
            });
    }, [partnerID]);

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.AREAS,]);

    const [searchParams, setSearchParams] = useSearchParams();

    const [nameDialogOpen, toggleNameDialog] = useState(false);

    const handleOnSaveClicked = () => {
        if (!newPath) return;

        if (selectedArea) { // editing existing area
            dispatch(AreasMethods.update(selectedArea, {
                path: newPath,
            }));
        }
        else { // create new area
            toggleNameDialog(true);
        }
    }

    const submitNewArea = (name: string) => {
        if (!partnerID || !newPath) return;

        dispatch(AreasMethods.create(partnerID, {
            name: name,
            path: newPath,
        }))
            .then(area => {
                if (area) { // area successfully created
                    toggleNameDialog(false); // hide dialog

                    searchParams.set(SearchParam.AREA_ID, area.ID);
                    setSearchParams(searchParams);
                }
            });
    }

    return (
        <Grid
            container
            direction="row"
            display="flex"
            justifyContent="flex-start"
            alignContent="flex-start"
            columnSpacing={1}
            rowSpacing={1}
            width="100%"
            flexGrow={1}
            mb={2}
        >
            {(isAdmin || hasSeveralPartners) && (
                <Grid item>
                    <PartnerSelect
                        parentLoading={loading}
                    />
                </Grid>
            )}
            <Grid item flexGrow={1} />
            <Grid item xs="auto" sx={{ pointerEvents: "all" }}>
                <Stack direction="row" spacing={1}>
                    {editing ? ( // controls when editing an area
                        <>
                            <Box minWidth={400}>
                                <OsmSearchBar />
                            </Box>
                            <ActionButton
                                color="error"
                                icon={<ClearIcon />}
                                onClick={() => dispatch(SelectedAreaActions.selectArea({
                                    area: null,
                                    editing: false,
                                }))}
                            />
                            {newPath && newPath.length > 0 && (
                                <ActionButton
                                    color="warning"
                                    icon={<UndoRoundedIcon />}
                                    onClick={() => dispatch(SelectedAreaActions.removeLast())}
                                />
                            )}
                            <ActionButton
                                id={SAVE_AREA_BUTTON_ID}
                                color="success"
                                startIcon={<CheckRoundedIcon />}
                                onClick={handleOnSaveClicked}
                                disabled={!newPath || newPath.length < 3}
                            >
                                {t("save", { ns: Namespace.ACTIONS })}
                            </ActionButton>
                        </>
                    ) : ( // controls when not editing an area
                        <ActionButton
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                dispatch(SelectedAreaActions.selectArea({
                                    area: null,
                                    editing: true,
                                }));
                            }}
                        >
                            {t("create", { ns: Namespace.AREAS })}
                        </ActionButton>
                    )}

                    <Box
                        pl={3}
                        position="relative"
                        width={theme => theme.spacing(9)}
                        height={theme => theme.spacing(7)}
                    >
                        <AreasHelpSpeedDial />
                    </Box>
                </Stack>

            </Grid>

            <AreaNameDialog
                open={nameDialogOpen}
                defaultName={selectedArea?.name || selectedOsmAreaName || ""}
                onClose={() => toggleNameDialog(false)}
                onSubmit={submitNewArea}
            />
        </Grid>
    );
}

export default AreasControls;