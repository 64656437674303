import { ReactNode } from "react";
import { APILoadingStatus, Map, MapCameraChangedEvent, MapCameraProps, MapMouseEvent, useApiIsLoaded, useApiLoadingStatus } from "@vis.gl/react-google-maps";
import { Typography } from "@mui/material";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { preGenerateIcons } from "./MarkerIcon";

type MapWrapperProps = {
    mapId: string;
    initialCamera: MapCameraProps;
    children?: ReactNode;
    onCameraChanged?: (ev: MapCameraChangedEvent) => void;
    mapTypeControlOptions?: google.maps.MapTypeControlOptions;
    onClick?: (ev: MapMouseEvent) => void;

};

export function MapWrapper({
    mapId,
    initialCamera,
    children,
    onCameraChanged,
    mapTypeControlOptions = {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE],
    },
    onClick
}: MapWrapperProps) {
    const { t } = useTranslation([Namespace.COMMONS]);

    const apiIsLoaded = useApiIsLoaded();
    const status = useApiLoadingStatus();

    if (status === APILoadingStatus.FAILED) {
        return <Typography variant="body1">{t("map_loading_failed", { ns: Namespace.COMMONS })}</Typography>;
    }

    if (!apiIsLoaded) {
        return <Typography variant="body1">{t("map_loading", { ns: Namespace.COMMONS })}</Typography>;
    }

    preGenerateIcons(); //genreate svg icons for marker

    return (
        <Map
            mapId={mapId}
            center={new google.maps.LatLng(initialCamera.center)}
            zoom={initialCamera.zoom}
            onCameraChanged={onCameraChanged}
            mapTypeControlOptions={mapTypeControlOptions}
            onClick={onClick}
        >
            {children}
        </Map>
    );
}
