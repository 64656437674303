import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import MissionsMethods from 'controllers/mission';
import { MissionDbData, MissionStatus } from 'models/Missions';
import { useAppDispatch, useAppSelector, usePartnerID } from 'store/hooks';
import ActionButton from 'components/_include/Buttons/ActionButton';

interface ConfirmMarkAsDoneDialogProps {
}

/**
 * A dialog that confirms marking the mission as done.
 *
 * Displays a prompt and a button to confirm the action.
 * On confirmation, updates the mission status to COMPLETED and then close the dialog.
 */
const ConfirmMarkAsDoneDialog: React.FC<ConfirmMarkAsDoneDialogProps> = () => {
    const { t } = useTranslation([Namespace.ACTIONS]);
    const missionID = useAppSelector(state => state.missions.selectedMission.data?.ID);
    const partnerID = usePartnerID();
    const dispatch = useAppDispatch();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    /**
    * Handles the confirmation event to mark the mission as done.
    *
    * @returns {Promise<void>}
    */
    const onMarkAsDoneConfirmed = async () => {
        if (!partnerID || !missionID) return;
        const updatedMissionData: Partial<MissionDbData> = { status: MissionStatus.COMPLETED };
        await dispatch(MissionsMethods.update(partnerID, missionID, updatedMissionData));
        setConfirmDialogOpen(false);
    };

    /**
    * Handles the click event to open the "mark as done" confirmation dialog.
    */
    const onConfirmMarkAsDoneClick = () => {
        setConfirmDialogOpen(true);
    };

    /**
     * Handles the click event to close the "mark as done" confirmation dialog.
     */
    const onCancelMarkAsDoneClick = () => {
        setConfirmDialogOpen(false);
    };

    return (
        <>
            <ActionButton
                onClick={onConfirmMarkAsDoneClick}
                color="success"
            >
                {t('mark_as_done', { ns: Namespace.ACTIONS })}
            </ActionButton>
            <Dialog open={confirmDialogOpen} onClose={onCancelMarkAsDoneClick}>
                <DialogTitle>
                    {t('mark_as_done', { ns: Namespace.ACTIONS })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('confirm_done_message', { ns: Namespace.MISSIONS })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={onCancelMarkAsDoneClick}>
                        {t('cancel', { ns: Namespace.ACTIONS })}
                    </Button>
                    <Button color="success" onClick={onMarkAsDoneConfirmed}>
                        {t('confirm', { ns: Namespace.ACTIONS })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmMarkAsDoneDialog;
