import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadableContext, RootState } from "store/store";
import { SensitizationAddresses, ReportStats, ResponsivenessLevel } from "models/Missions";
import { TOPICS } from "constants/address_report";

const missionAddressesAdapter = createEntityAdapter<SensitizationAddresses>({
    selectId: address => address.ID,
    sortComparer: (a, b) => {
        const keyA = a.ID || "";
        const keyB = b.ID || "";
        return keyA.localeCompare(keyB);
    },
});

interface MissionAddressState extends LoadableContext {
    aggregatedStats: ReportStats | null;
    selectedRowsIds: string[],
    selectedSortingPointsIds: string[],
}

const initialState: MissionAddressState = {
    aggregatedStats: null,
    loading: false,
    error: null,
    selectedRowsIds: [],
    selectedSortingPointsIds: [],
};

const aggregateStatsFromAddresses = (addresses: SensitizationAddresses[]): ReportStats => {
    const aggregatedStats: ReportStats = {
        totalAddressesVisited: 0,
        inhabitantsSpokenCount: 0,
        inhabitantsReceptiveCounts: {
            [ResponsivenessLevel.Responsive]: 0,
            [ResponsivenessLevel.Neutral]: 0,
            [ResponsivenessLevel.NotResponsive]: 0,
        },
        selectedTopicsCounts: {},
        communicationMethodsCounts: {},
    };

    TOPICS.forEach((topic) => {
        aggregatedStats.selectedTopicsCounts![topic] = 0;
    });


    addresses.forEach((address) => {
        if (address.visited) {
            aggregatedStats.totalAddressesVisited += 1;

            const report = address.report;

            if (report) {
                // Sum inhabitantsSpokenCount
                if (report.inhabitantsSpoken) {
                    aggregatedStats.inhabitantsSpokenCount = (aggregatedStats.inhabitantsSpokenCount ?? 0) + 1;
                }

                // Sum inhabitantsReceptiveCounts
                if (report.inhabitantsReceptive !== null && report.inhabitantsReceptive !== undefined) {
                    const mood = report.inhabitantsReceptive;

                    // Use a stricter check for null and undefined
                    if (aggregatedStats.inhabitantsReceptiveCounts == null) {
                        aggregatedStats.inhabitantsReceptiveCounts = {
                            [ResponsivenessLevel.Responsive]: 0,
                            [ResponsivenessLevel.Neutral]: 0,
                            [ResponsivenessLevel.NotResponsive]: 0,
                        };
                    }

                    // Ensure the mood key is initialized
                    if (aggregatedStats.inhabitantsReceptiveCounts[mood] == null) {
                        aggregatedStats.inhabitantsReceptiveCounts[mood] = 0;
                    }

                    aggregatedStats.inhabitantsReceptiveCounts[mood] += 1;
                }

                // Sum selectedTopicsCounts
                if (report.selectedTopics && report.selectedTopics.length > 0) {
                    if (aggregatedStats.selectedTopicsCounts == null) {
                        aggregatedStats.selectedTopicsCounts = {};
                    }

                    report.selectedTopics.forEach((topic) => {
                        aggregatedStats.selectedTopicsCounts![topic] = (aggregatedStats.selectedTopicsCounts![topic] ?? 0) + 1;
                    });
                }

                // Sum communicationMethodsCounts
                if (report.communicationMethods && report.communicationMethods.length > 0) {
                    if (aggregatedStats.communicationMethodsCounts == null) {
                        aggregatedStats.communicationMethodsCounts = {};
                    }

                    report.communicationMethods.forEach((method) => {
                        aggregatedStats.communicationMethodsCounts![method] = (aggregatedStats.communicationMethodsCounts![method] ?? 0) + 1;
                    });
                }
            }
        }
    });

    return aggregatedStats;
};


export const missionAddressesSlice = createSlice({
    name: 'mission_addresses',
    initialState: missionAddressesAdapter.getInitialState(initialState),
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.loading = false;
            state.error = null;
        },
        setSelectedRowsIds: (state, action: PayloadAction<string[]>) => {
            state.selectedRowsIds = action.payload;
        },
        clearSelectedRowsIds: (state) => {
            state.selectedRowsIds = [];
        },
        setSelectedSortingPointIds: (state, action: PayloadAction<string[]>) => {
            state.selectedSortingPointsIds = action.payload;
        },
        clearSelectedSortingPointIds: (state) => {
            state.selectedSortingPointsIds = [];
        },
        setList: (state, { payload: addresses }: PayloadAction<SensitizationAddresses[]>) => {
            state.loading = true;
            missionAddressesAdapter.setAll(state, addresses);
            // Compute aggregated stats
            state.aggregatedStats = aggregateStatsFromAddresses(addresses);
            state.loading = false;
        },
        addItem: (state, { payload: address }: PayloadAction<SensitizationAddresses>) => {
            state.loading = false;
            missionAddressesAdapter.addOne(state, address);
        },
        addItems: (state, { payload: address }: PayloadAction<SensitizationAddresses[]>) => {
            state.loading = false;
            missionAddressesAdapter.addMany(state, address);
        },
        removeItem: (state, { payload: addressKey }: PayloadAction<string>) => {
            missionAddressesAdapter.removeOne(state, addressKey);
        },
        removeAllItems: (state) => {
            missionAddressesAdapter.removeAll(state);
        },
        updateItem: (state, { payload: { ID, data } }: PayloadAction<{ ID: string, data: Partial<SensitizationAddresses> }>) => {
            missionAddressesAdapter.updateOne(state, {
                id: ID,
                changes: data,
            });
        },
        updateItems: missionAddressesAdapter.updateMany,
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        updateAddress: (state, { payload: address }: PayloadAction<SensitizationAddresses>) => {
            if (state.entities[address.addressKey]) {
                // Address exists, remove it
                missionAddressesAdapter.removeOne(state, address.addressKey);
            } else {
                // Address does not exist, add it with default 'visited' status
                missionAddressesAdapter.addOne(state, { ...address, visited: false });
            }
        },
    },
});

export const MissionAddressesActions = {
    ...missionAddressesSlice.actions,
};

export const {
    selectAll: selectAllMissionAddresses,
    selectById: selectMissionAddressesById,
    selectIds: selectMissionAddressesIds
} = missionAddressesAdapter.getSelectors((state: RootState) => state.missions.missionAddresses);

const MissionAddressesReducer = missionAddressesSlice.reducer;

export default MissionAddressesReducer;