import { useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector, useMapCamera } from "store/hooks";
import { getBoundsZoomLevel, getPointsBoundsAndCenter } from "helpers/geo";
import { MapWrapper } from "components/_include/Maps/MapWrapper";
import { BatchesActions } from "store/reducers/batches/list";
import { SortingMapActions } from "store/reducers/batches/sorting_map";
import BatchAddressMarkers from "./Markers/BatchAddressMarkers";
import MissionAddressMarkers from "../../Missions/MissionDetail/MissionMap/MissionAddressMarkers";
import SortingPointMissionMarkers from "../../Missions/MissionDetail/MissionMap/SortingPointMissionMarkers";
import { selectAllMissionAddresses } from "store/reducers/missions/addresses/list";
import { selectFilteredSortingPoints } from "store/selectors";
import { MissionStatus } from "models/Missions";

type SortingMapProps = {
    width: number | undefined;
    height: number | undefined;
    showForMissions?: boolean;
};

export default function SortingMap({ width, height, showForMissions }: SortingMapProps) {
    const dispatch = useAppDispatch();
    const defaultCenter = useAppSelector((state) => state.batches.collectionMap.defaultCenter);
    const defaultZoom = useAppSelector((state) => state.batches.collectionMap.defaultZoom);
    const missionAddresses = useAppSelector(selectAllMissionAddresses);
    const filteredPoints = useAppSelector(selectFilteredSortingPoints);
    const missionStatus = useAppSelector(state => state.missions.selectedMission.data?.status);

    const { cameraProps, handleCameraChange, setCameraProps } = useMapCamera({
        center: defaultCenter,
        zoom: defaultZoom,
    });

    /** Recalculate bounds and zoom when map points are loaded. */
    useEffect(() => {
        if (width && height) {
            // Combine missionAddresses and filteredPoints
            const missionAddressesCoords = missionAddresses.map((addr) => ({
                lat: addr.lat,
                lng: addr.lng,
            }));

            const sortingPointsCoords = filteredPoints.map((point) => {
                const [lat, lng] = point.geometry.coordinates;
                return { lat, lng };
            });

            const allCoords = [...missionAddressesCoords, ...sortingPointsCoords];

            // If we have at least one coordinate, recalculate bounds
            if (allCoords.length > 0) {
                const { bounds, center } = getPointsBoundsAndCenter(allCoords);
                const newZoom = getBoundsZoomLevel(bounds, { width, height });
                setCameraProps({ center, zoom: newZoom });
            } else {
                // No markers, fallback to default center and zoom
                setCameraProps({ center: defaultCenter, zoom: defaultZoom });
            }
        }
    }, [showForMissions, missionAddresses, filteredPoints, width, height, setCameraProps, defaultCenter, defaultZoom]);


    const handleMarkerClick = useCallback((addressKey: string) => {
        dispatch(BatchesActions.unselectBatch());
        dispatch(SortingMapActions.selectAddressKey(addressKey));
    }, [dispatch]);

    return (
        <MapWrapper mapId={process.env.REACT_APP_MISSION_MAP_ID ?? 'mapid'} initialCamera={cameraProps} onCameraChanged={handleCameraChange}>
            {showForMissions ? (
                <>
                    <MissionAddressMarkers onClick={handleMarkerClick} />
                    {missionStatus !== MissionStatus.COMPLETED && (
                        <SortingPointMissionMarkers onClick={handleMarkerClick} />
                    )}
                </>
            ) : (
                <BatchAddressMarkers onMarkerClick={handleMarkerClick} />
            )}
        </MapWrapper>
    );
}
