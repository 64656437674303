import React from "react";
import { useSelector } from "react-redux";
import { selectFilteredSortingPoints } from "store/selectors";
import MissionSortingPointMarker from "./MissionSortingPointMarker";


type SortingPointMissionMarkersProps = {
    onClick: (addressKey: string) => void;
};

/**
 * List of markers for each address matching filters on the mission page, 
 * but not added yet to the mission.
 */
export default function SortingPointMissionMarkers(prop: SortingPointMissionMarkersProps) {
    const { onClick } = prop;
    const filteredPoints = useSelector(selectFilteredSortingPoints);

    return (
        <>
            {filteredPoints.map((point) => {
                return (
                    <MissionSortingPointMarker key={point.properties.hereID} onClick={onClick} point={point} />
                );
            })}
        </>
    );
}
