import { useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector, usePartnerID } from 'store/hooks';
import { SensitizationAddresses } from "models/Missions";
import { getBatchKey } from 'store/reducers/batches/places';
import ActionButton from 'components/_include/Buttons/ActionButton';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFilteredSortingPoints } from 'store/selectors';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import FichaDataGrid from '../DataTable/FichaDataGrid';
import MissionAddressesMethods from 'controllers/sensitization_addresses';
import { TrashCount } from 'constants/trash';
import { AddressRowData } from './MissionAddressesTable/MissionAddressesTable';
import { SortingMapActions } from 'store/reducers/batches/sorting_map';
import { MissionAddressesActions } from 'store/reducers/missions/addresses/list';

function SortingPointDataTable() {
    const dispatch = useAppDispatch();
    const filteredPoints = useSelector(selectFilteredSortingPoints);
    const selectedRowsIds = useAppSelector((state) => state.missions.missionAddresses.selectedSortingPointsIds);
    const { t } = useTranslation([Namespace.MISSIONS]);
    const { missionID } = useParams();
    const partnerID = usePartnerID();


    const rows = useMemo(() => {
        return filteredPoints.map((sortingPoint) => {
            const addressKey = getBatchKey({
                hereID: sortingPoint.properties.hereID,
                address: sortingPoint.properties.address,
            });

            const batchesCount = sortingPoint.properties.batchesCount ?? 0;

            const addressData = {
                addressKey: addressKey,
                house: sortingPoint.properties.address.houseNumber,
                street: sortingPoint.properties.address.street,
                city: sortingPoint.properties.address.city,
                postalCode: sortingPoint.properties.address.postalCode,
                errorDetail: sortingPoint.properties.errors,
                errorsCount: sortingPoint.properties.errorsCount,
                lat: sortingPoint.geometry.coordinates[0],
                lng: sortingPoint.geometry.coordinates[1],
                batchesCount: batchesCount,
            };
            return addressData;
        });
    }, [filteredPoints]);

    const handleAddAddress = useCallback(async () => {
        if (!missionID || !partnerID) {
            console.error('Mission ID or Partner ID is missing.');
            return;
        }

        // Map selectionModel IDs to corresponding addresses
        const selectedAddresses = selectedRowsIds
            .map((id) => rows.find((row) => row.addressKey === id))
            .filter(
                (addressData): addressData is typeof rows[number] => addressData !== undefined
            );

        if (selectedAddresses.length === 0) {
            // No addresses selected
            return;
        }

        // Prepare addresses without IDs (IDs will be generated in the controller)
        const addressesToAdd: Omit<SensitizationAddresses, 'ID'>[] = selectedAddresses.map((addressRowData) => ({
            address: {
                houseNumber: addressRowData.house,
                street: addressRowData.street,
                city: addressRowData.city,
                postalCode: addressRowData.postalCode,
            },
            addressKey: addressRowData.addressKey,
            errors: addressRowData.errorDetail,
            errorsCount: addressRowData.errorsCount,
            lat: addressRowData.lat,
            lng: addressRowData.lng,
            batchesCount: addressRowData.batchesCount,
            visited: false,
        }));

        // Call the new controller method
        dispatch(
            MissionAddressesMethods.addAddressesToMission(partnerID, missionID, addressesToAdd)
        );

    }, [missionID, partnerID, selectedRowsIds, rows, dispatch]);

    /** Dispatch action to highlight the hovered address on the map. */
    const handleRowMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        const rowId = event.currentTarget.getAttribute('data-id');
        dispatch(SortingMapActions.hoverAddressKey(rowId));
    };

    /** Dispatch action to stop highlighting the hovered address on the map. */
    const handleRowMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
        const rowId = event.currentTarget.getAttribute('data-id');
        dispatch(SortingMapActions.leavePlaceID(rowId));
    };

    const columns: GridColDef[] = useMemo(() => [
        { field: 'house', headerName: t('house', { ns: Namespace.MISSIONS }) },
        { field: 'street', headerName: t('street', { ns: Namespace.MISSIONS }), width: 150 },
        { field: 'city', headerName: t('city', { ns: Namespace.MISSIONS }) },
        {
            field: 'errorsCount',
            headerName: t('errors_count', { ns: Namespace.MISSIONS }),
            type: 'number',
        },
        {
            field: 'errorDetail',
            headerName: t('error_detail', { ns: Namespace.MISSIONS }),
            minWidth: 155,
            renderCell: (params: GridRenderCellParams<Partial<TrashCount>, AddressRowData>) => {
                const errors = params.value || {};

                if (typeof errors !== 'object') {
                    return;
                }

                return (
                    <Typography variant="body2">
                        {Object.entries(errors).map(([trashType, count], index) =>
                            t(`${trashType}_count`, { ns: Namespace.WASTES, context: 'small', count: count })
                        ).join(', ')}
                    </Typography>
                );
            },
        },
    ], [t]);

    return (
        <Box>
            <Box display="flex" marginBottom={2} justifyContent="space-between">
                <Typography variant="h3">
                    {t('add_mission_addresses', { ns: Namespace.MISSIONS })}
                </Typography>
                <ActionButton
                    size="normal"
                    color="secondary"
                    disabled={selectedRowsIds.length === 0}
                    onClick={handleAddAddress}
                >
                    {selectedRowsIds.length === 0
                        ? t('select_an_address', { ns: Namespace.MISSIONS })
                        : t('add_addresses', {
                            ns: Namespace.MISSIONS,
                            count: selectedRowsIds.length,
                        })}
                </ActionButton>
            </Box>
            <FichaDataGrid
                rows={rows}
                columns={columns}
                checkboxSelection
                onRowSelectionModelChange={(newSelection) =>
                    dispatch(MissionAddressesActions.setSelectedSortingPointIds(newSelection as string[]))
                }
                slotProps={{
                    row: {
                        onMouseEnter: handleRowMouseEnter,
                        onMouseLeave: handleRowMouseLeave,
                    },
                }}
                getRowId={(row) => row.addressKey}
            />
        </Box>
    );
}

export default SortingPointDataTable;
