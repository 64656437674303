import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { getPictureURL, StorageType } from 'helpers/storage';


type ImageProps = {
    imageURL: string;
};

const AddressReportImage: React.FC<ImageProps> = ({ imageURL }) => {
    const { t } = useTranslation([Namespace.MISSION_REPORT]);

    const [pictureURL, setPictureURL] = useState("");

    useEffect(() => {
        getPictureURL(imageURL, StorageType.MISSION_ADDRESSES)
            .then(url => {
                if (url) setPictureURL(url);
            });
    }, [imageURL]);


    return (
        <Box
            position="relative"
            width="100%"
            height="auto"
            border="1px solid #ddd"
            borderRadius="8px"
        >
            <img
                src={pictureURL}
                alt={t('preview', { ns: Namespace.MISSION_REPORT })}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
        </Box>

    );
};

export default AddressReportImage;
