import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { CommunicationMethod } from 'constants/address_report';

type CommunicationMethodsProps = {
    communicationMethods: string[];
};

const CommunicationMethods: React.FC<CommunicationMethodsProps> = ({ communicationMethods }) => {
    const { t } = useTranslation([Namespace.MISSION_REPORT]);

    return (
        <FormControl component="fieldset" sx={{ mb: 3 }}>
            <Typography variant="subtitle1">
                {t('communication.methods.question', { ns: Namespace.MISSION_REPORT })}
            </Typography>
            <FormGroup row>
                {Object.values(CommunicationMethod).map((method) => (
                    <FormControlLabel
                        key={method}
                        control={
                            <Checkbox
                                checked={communicationMethods.includes(method)}
                                disabled={!communicationMethods.includes(method)}
                            />
                        }
                        label={t(`communication.methods.${method}`, { ns: Namespace.MISSION_REPORT })}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

export default CommunicationMethods;
