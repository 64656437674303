import { Timestamp } from "firebase/firestore";
import { UserData } from "./User";
import { PositionedNamedErrorsByAddress } from "constants/types";

export enum MissionStatus {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED'
}

/**
 * Possible status for an address that ambassadors should visit during a door-to-door mission.
 */
export enum MissionAddressStatus {
    NOT_ADDED = "not_added",
    SELECTED_TO_ADD = "selected_to_add",
    PENDING = "pending",
    VISITED = "visited",
    ABSENT = "absent",
};

export enum ResponsivenessLevel {
    Responsive = 'responsive',
    Neutral = 'neutral',
    NotResponsive = 'not_responsive',
}

export type MoodStats = { [level in ResponsivenessLevel]: number };

export type ReportStats = {
    totalAddressesVisited: number;
    inhabitantsSpokenCount?: number;
    inhabitantsReceptiveCounts?: {
        [key in ResponsivenessLevel]: number;
    };
    selectedTopicsCounts?: {
        [key: string]: number;
    };
    communicationMethodsCounts?: {
        [key: string]: number;
    };
};

export type MissionDbData = {
    ID: string;
    name: string;
    deadline: Timestamp | undefined;
    createdAt: Timestamp;
    /** For each ambassador added to the mission, indicate the number of addresses assigned to them. */
    assigned: { [email: string]: number };
    mapImageURL?: string;
    status?: MissionStatus;
    addressesCount: number;
    addressesVisitedCount: number;
    reportStats?: ReportStats;
}

export type MissionsData = Omit<MissionDbData, "deadline" | "createdAt"> & {
    deadline: string | undefined;
    createdAt?: string;
}

export type ReportFormData = {
    inhabitantsSpoken: boolean | null;
    communicationMethods: string[];
    selectedTopics: string[];
    inhabitantsReceptive: ResponsivenessLevel | null;
    otherComments: string;
    additionalInfo: string;
    imageURLs: string[];
};

export type SensitizationAddresses = PositionedNamedErrorsByAddress & {
    ID: string;
    batchesCount: number;
    visited: boolean;
    assigned?: Pick<UserData, "name" | "email">;
    report?: ReportFormData;
};

/**
 * Colors used to describe each possible status for a sensitization address.
 */
export const MISSION_ADDRESS_COLOR_MAPPING: { [status in MissionAddressStatus]: "info" | "secondary" | "success" | "error" } = {
    [MissionAddressStatus.NOT_ADDED]: "info",
    [MissionAddressStatus.SELECTED_TO_ADD]: "secondary",
    [MissionAddressStatus.PENDING]: "secondary",
    [MissionAddressStatus.VISITED]: "success",
    [MissionAddressStatus.ABSENT]: "error",
}