import React from "react";
import { useAppSelector, usePanorama } from "store/hooks";
import { selectAllSortingPoints } from "store/reducers/batches/sorting_map";
import { getBatchKey } from "store/reducers/batches/places";
import { formatAddress } from "helpers/geo";
import AddressMarker from "../AddressMarker";

type BatchAddressMarkersProps = {
    onMarkerClick: (addressKey: string) => void;
};

/**
 * Round dot marker used on the "Map" page, with color indicating number of errors.
 */
export default function BatchAddressMarkers({ onMarkerClick }: BatchAddressMarkersProps) {
    const points = useAppSelector(selectAllSortingPoints);
    const { map, panoramaInstance } = usePanorama();

    return (
        <>
            {points.map((point) => {
                const addressKey = getBatchKey({
                    hereID: point.properties.hereID,
                    address: point.properties.address,
                });

                const { address, dangerLevel } = point.properties;
                const [lat, lng] = point.geometry.coordinates;

                return (
                    <AddressMarker
                        key={addressKey}
                        addressKey={addressKey}
                        dangerLevel={dangerLevel}
                        address={formatAddress(address)}
                        lat={lat}
                        lng={lng}
                        onClick={onMarkerClick}
                        mapInstance={map}
                        panoramaInstance={panoramaInstance}
                    />
                );
            })}
        </>
    );
}
