import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';

type YesNoQuestionProps = {
    question: string;
    value: boolean | null;
};

const YesNoQuestion: React.FC<YesNoQuestionProps> = ({ question, value }) => {
    const { t } = useTranslation([Namespace.MISSION_REPORT, Namespace.ACTIONS]);

    return (
        <FormControl component="fieldset" sx={{ mb: 3 }}>
            <Typography variant="subtitle1">{t(question, { ns: Namespace.MISSION_REPORT })}</Typography>
            <RadioGroup
                row
                value={value === null ? "" : value}
            >
                <FormControlLabel value={true} disabled={value === false} control={<Radio />} label={t('yes', { ns: Namespace.ACTIONS })} />
                <FormControlLabel value={false} disabled={value === true} control={<Radio />} label={t('no', { ns: Namespace.ACTIONS })} />
            </RadioGroup>
        </FormControl>
    );
};

export default YesNoQuestion;
