import { Box, Container } from '@mui/material';
import PageTitle from 'components/_include/PageTitle';
import { useIsAdmin } from 'store/hooks';
import OnboardingSetupCards from '../Onboarding/OnboardingSetupCards';
import DefaultMapBoundsSection from './DefaultMapBounds/DefaultMapBoundsSection';
import DangerZoneSection from './DangerZone/DangerZoneSection';
import PartnerCanAccessSettingsCheckbox from './PartnerAccessCheckboxes';

type SettingsPageProps = {

}

function SettingsPage(props: SettingsPageProps) {
    const { } = props;

    const isAdmin = useIsAdmin();

    return (
        <Container
            sx={{
                display: "flex",
                flexDirection: "column"
            }}
        >
            <PageTitle />

            <Box 
                display="flex"
                flexDirection="column"
                gap={4}
                mb={4}
                >
                <Box
                    display="flex"
                    height={theme => theme.spacing(75)}
                >
                    <OnboardingSetupCards />

                </Box>

                {isAdmin && (
                    <PartnerCanAccessSettingsCheckbox />
                )}
            </Box>
            
            <DefaultMapBoundsSection />

            {isAdmin && (
                <DangerZoneSection />
            )}
        </Container>
    );
}

export default SettingsPage;