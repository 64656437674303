import { Grid } from "@mui/material";
import { useAppSelector } from "store/hooks";
import ReportNotAvailableCard from "./ReportNotAvailableCard";
import ReportStatsCard from "./ReportStatsCard";
import { selectMissionsIds } from "store/reducers/missions/list";

function MissionsReportStats() {
    const aggregatedStatsForMissions = useAppSelector(state => state.missions.missionsList.aggregatedStats)!;
    const hasMissions = useAppSelector(state => selectMissionsIds(state).length > 0);

    return (
        <Grid container paddingTop={4}>
            {aggregatedStatsForMissions && hasMissions ? (
                <ReportStatsCard aggregatedStats={aggregatedStatsForMissions} />
            ) : (
                <ReportNotAvailableCard />
            )
            }
        </Grid>
    );
}

export default MissionsReportStats;
