import { AddressStatsPointFeature, GRENOBLE_COORDINATES, DEFAULT_ZOOM_LEVEL, formatAddress } from "helpers/geo";
import { createEntityAdapter, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { LoadableContext, RootState } from "store/store";
import { getBatchKey } from "./places";

const addressesPointsAdapter = createEntityAdapter<AddressStatsPointFeature>({
    selectId: point => getBatchKey(point.properties),
    sortComparer: (place1, place2) => {
        const errorsSorting = place2.properties.errorsCount - place1.properties.errorsCount;
        if (errorsSorting !== 0) return errorsSorting; // most errors first
        return place2.properties.batchesCount - place1.properties.batchesCount; // same errors: most batches first
    },
});

type MapContext = LoadableContext & {
    defaultCenter: google.maps.LatLngLiteral;
    defaultBounds: google.maps.LatLngBoundsLiteral | undefined;
    defaultZoom: number;
    hoveredAddressKey: string | null;
    selectedAddressKey: string | null;
    totalBatchesCount: number;
    filterString: string;
    filteredAddressesIDs: EntityId[];
};

const initialState: MapContext = {
    loading: false,
    error: null,
    defaultCenter: GRENOBLE_COORDINATES,
    defaultBounds: {
      north: 0,
      south: 0,
      east: 0,
      west: 0,
    },
    defaultZoom: DEFAULT_ZOOM_LEVEL,
    hoveredAddressKey: null,
    selectedAddressKey: null,
    totalBatchesCount: 0,
    filterString: "",
    filteredAddressesIDs: [],
};

export const sortingMapSlice = createSlice({
    name: 'sorting_map',
    initialState: addressesPointsAdapter.getInitialState(initialState),
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
            state.selectedAddressKey = null;
        },
        removeMapPoints: (state) => {
            state.loading = false;
            state.selectedAddressKey = null;
            state.totalBatchesCount = 0;
            addressesPointsAdapter.removeAll(state);
        },
        setMapPoints: (state, { payload: { center, bounds, points, totalBatchesCount } }: PayloadAction<{ center: google.maps.LatLngLiteral, bounds: google.maps.LatLngBoundsLiteral, points: AddressStatsPointFeature[], totalBatchesCount: number }>) => {
            state.loading = false;
            state.defaultCenter = center;
            state.defaultBounds = bounds;
            state.totalBatchesCount = totalBatchesCount;
            addressesPointsAdapter.setAll(state, points);
            state.filteredAddressesIDs = [...state.ids];
        },
        setMapBounds: (state, { payload: { center, bounds } }: PayloadAction<{ center: google.maps.LatLngLiteral, bounds: google.maps.LatLngBoundsLiteral }>) => {
            state.defaultCenter = center;
            state.defaultBounds = bounds;
        },
        setCenterAndZoom: (state, { payload: { center, zoom } }: PayloadAction<{ center: google.maps.LatLngLiteral, zoom: number }>) => {
            state.defaultCenter = center;
            state.defaultZoom = zoom;
        },
        resetMapBounds: (state) => {
            state.defaultBounds = undefined;
        },
        /** Select a point on sorting map to display all batches associated to this address. */
        selectAddressKey: (state, { payload: addressKey }: PayloadAction<string | null>) => {
            state.selectedAddressKey = addressKey;
            if (addressKey) { // center map on selected place
                const addressPoint = state.entities[addressKey];
                if (addressPoint) {
                    const [lat, lng] = addressPoint.geometry.coordinates;
                    state.defaultCenter = { lat, lng };
                }
            }
        },
        /** Highlight a point on sorting map. */
        hoverAddressKey: (state, { payload: placeID }: PayloadAction<string | null>) => {
            state.hoveredAddressKey = placeID;
        },
        /** Stop highlighting a point on sorting map. */
        leavePlaceID: (state, { payload: placeID }: PayloadAction<string | null>) => {
            if (placeID === state.hoveredAddressKey) state.hoveredAddressKey = null;
        },
        setError: (state, { payload: error }: PayloadAction<string>) => {
            state.loading = false;
            state.error = error;
        },
        setFilterString: (state, { payload: filterString }: PayloadAction<string>) => {
            state.filterString = filterString;
            state.filteredAddressesIDs = state.ids.filter(placeID => {
                return formatAddress(state.entities[placeID]!.properties.address)
                    .toLowerCase()
                    .includes(filterString.toLowerCase());
            });
        },
    },
});

export const SortingMapActions = sortingMapSlice.actions;

export const {
    selectAll: selectAllSortingPoints,
    selectById: selectSortingPointByAddressKey,
    selectIds: selectAllSortingAddressesKeys,
} = addressesPointsAdapter.getSelectors((state: RootState) => state.batches.sortingMap);

const SortingMapReducer = sortingMapSlice.reducer;

export default SortingMapReducer;