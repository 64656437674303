import { VALIDATION_WASTES_COLORS } from "constants/trash";
import { hexToRgba } from "helpers/draw";
import Moveable, { OnResize } from "react-moveable";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { MediaOverlayActions, selectResultBboxById } from "store/reducers/batches/media_overlay";
import { Box, Typography } from "@mui/material";
import { WastesInputsActions } from "store/reducers/batches/wastes_inputs";
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import { BBOX_LABEL_SUFFIX } from "models/BoundingBox";
import { useEffect, useMemo } from "react";
import { Shortcut } from "store/reducers/preferences/validation_shortcuts";

type BoundingBoxProps = {
    bboxID: string;
    scaleX: number;
    scaleY: number;
    canEditResults?: boolean;
}

const getTransformValues = (transformString: string) => {
    let transformX = 0, transformY = 0;
    const transformValues = transformString.match(/-?[\d.]+/g);
    if (transformValues) {
        transformX = transformValues[0] ? Number(transformValues[0]) : 0;
        transformY = transformValues[1] ? Number(transformValues[1]) : 0;
    }
    return [transformX, transformY];
}

export default function BoundingBox({ bboxID, scaleX, scaleY, canEditResults }: BoundingBoxProps) {

    const { t } = useTranslation([Namespace.WASTES]);

    const bbox = useAppSelector(state => selectResultBboxById(state, bboxID))!;

    const inDrawingMode = useAppSelector(state => state.batches.mediaOverlay.selectedTrashType !== null);
    const isDrawing = useAppSelector(state => state.batches.mediaOverlay.drawing);

    const isSelected = useAppSelector(state => state.batches.mediaOverlay.selectedBoxID === bboxID);
    const isHovered = useAppSelector(state => state.batches.mediaOverlay.hoveredBoxID === bboxID);

    const imageWidth = useAppSelector(state => state.batches.mediaOverlay.imageWidth);
    const imageHeight = useAppSelector(state => state.batches.mediaOverlay.imageHeight);

    const isHidden = bbox.hidden;

    const hideBoxShortcut = useAppSelector(state => state.preferences.validation[Shortcut.HIDE_BOX]);
    const deleteBoxShortcut = useAppSelector(state => state.preferences.validation[Shortcut.DELETE_BOX]);

    const dispatch = useAppDispatch();

    /** Hide or show box. */
    const toggleBoxHidden = () => {
        dispatch(MediaOverlayActions.updateOne({
            id: bbox.ID,
            changes: { hidden: !isHidden }
        }));
    }

    /** Remove box. */
    const removeBox = () => {
        // decrement count of instance from this trash type
        dispatch(WastesInputsActions.decrementOne({
            trashType: bbox.class,
        }));

        // remove box from drawing layer
        dispatch(MediaOverlayActions.removeOne(bboxID));
    }

    /**
     * Handle drag or resize done on the box
     */
    const onResizeEnd = (target: HTMLElement | SVGElement) => {
        const { style } = target;

        const [transformX, transformY] = getTransformValues(style.transform);

        const left = parseFloat(target.style.left) + transformX;
        const top = parseFloat(target.style.top) + transformY;
        const right = left + parseFloat(style.width);
        const bottom = top + parseFloat(style.height);

        target.style.left = `${left}px`;
        target.style.top = `${top}px`;
        target.style.transform = "";

        const newDims = [
            [
                Math.max(0, Math.round(left / scaleX)),
                Math.max(0, Math.round(top / scaleY)),
            ], 
            [
                Math.min(imageWidth, Math.round(right / scaleX)),
                Math.min(imageHeight, Math.round(bottom / scaleY)),
            ]
        ];

        dispatch(MediaOverlayActions.updateOne({
            id: bboxID,
            changes: {
                box: newDims,
            }
        }));
    }

    /**
     * Handle keyboard shortcuts
     */
    const handleKeyDown = (e: KeyboardEvent) => {
        switch (e.key) {
            case hideBoxShortcut: // hide the selected box
                toggleBoxHidden();
                break;

            case deleteBoxShortcut: // delete selected box
                removeBox();
                break;
        }
    }

    useEffect(() => {
        if (isSelected) {
            window.addEventListener("keydown", handleKeyDown);
            return () => { window.removeEventListener("keydown", handleKeyDown); };
        }
        else window.removeEventListener("keydown", handleKeyDown);
    }, [isSelected, isHidden, deleteBoxShortcut]);

    const { box } = bbox;

    /** Calculate box's dimensions. */
    const { dims, zIndex } = useMemo(() => {
        const width = (box[1][0] - box[0][0]) * scaleX;
        const height = (box[1][1] - box[0][1]) * scaleY;
        return {
            dims: {
                left: box[0][0] * scaleX,
                top: box[0][1] * scaleY,
                width: width,
                height: height,
            },
            zIndex: 9999 - Math.round(width * height / 100), // z-index: bring smallest boxes to the front
        }
    }, [box, scaleX, scaleY]);

    /** Force re-render of Moveable component when zoom changes. */
    const moveableKey = useMemo(() => Math.random(), [scaleX, scaleY]);

    /**
     * Box's background and border colors
     */
    const color = VALIDATION_WASTES_COLORS[bbox.class];
    const bgcolor = hexToRgba(color, isSelected || isHovered ? 0.3 : 0.1);

    return (
        <div
            style={{
                zIndex,
                // hide box if trash type is hidden, but still show when hovered in drawer
                ...(isHidden && !isHovered ? {
                    opacity: 0,
                    pointerEvents: "none",
                } : {
                    opacity: 1,
                    pointerEvents: "all",
                })
            }}
        >
            <Box
                id={bbox.ID}
                style={{
                    ...(inDrawingMode && { pointerEvents: "none" }), // prevent interaction when drawing boxes
                    cursor: "pointer",
                    position: "absolute",
                    border: `${isSelected || isHovered ? 2 : 1}px solid ${color}`,
                    backgroundColor: bgcolor,
                    ...dims,
                }}
            // onClick={() => { dispatch(MediaOverlayActions.selectBox(bboxID)); }}
            >
                <Typography
                    id={`${bboxID}${BBOX_LABEL_SUFFIX}`}
                    variant="caption"
                    fontFamily="Roboto Mono"
                    sx={{
                        position: "absolute",
                        top: -22,
                        backgroundColor: "#222",
                        color: color,
                        px: 0.5,
                        whiteSpace: "nowrap", // label will not wrap to a new line
                    }}
                >
                    {t(bbox.class, { ns: Namespace.WASTES, count: 1, context: "small" })}
                </Typography>
            </Box>

            {canEditResults && !isDrawing && (
                <Moveable
                    key={moveableKey}
                    target={`#${bbox.ID}`}
                    container={null}
                    hideDefaultLines
                    origin={false}

                    props={{
                        editable: true,
                    }}

                    /* Limit to parent's bounds */
                    snappable={true}
                    bounds={{ "left": 0, "top": 0, "right": 0, "bottom": 0, "position": "css" }}

                    /* draggable */
                    draggable={true}
                    throttleDrag={1}
                    onDrag={(e) => {
                        e.target.style.transform = e.transform;
                    }}
                    onDragEnd={({ target }) => onResizeEnd(target)}

                    /* resizable */
                    resizable={true}
                    // renderDirections={["nw", "ne", "se", "sw"]}
                    throttleResize={0}
                    onResize={({ target, width, height, drag }: OnResize) => {
                        target.style.width = `${width}px`;
                        target.style.height = `${height}px`;
                        target.style.transform = drag.transform;
                    }}
                    onResizeEnd={({ target }) => onResizeEnd(target)}
                />
            )}
        </div>
    );
}