import React from 'react';
import { Grid, FormControl } from '@mui/material';
import AddressReportImage from './AddressReportImage';


type ImageViewerProps = {
    images: string[];
};

const AddressReportImageGrid: React.FC<ImageViewerProps> = ({ images }) => {;

    return (
        <FormControl component="fieldset">
            <Grid container spacing={2}>
                {images.map((image) => (
                    <Grid item key={image}>
                        <AddressReportImage imageURL={image} />
                    </Grid>
                ))}
            </Grid>
        </FormControl>
    );
};

export default AddressReportImageGrid;
