import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { getBatchKey } from './reducers/batches/places';
import { AddressStatsPointFeature } from 'helpers/geo';
import { selectAllSortingPoints } from './reducers/batches/sorting_map';

/**
 * Selects sorting points that are not already included in the mission addresses.
 * Filters out points that lack a valid house number or street.
 *
 * @function selectFilteredSortingPoints
 * @param {RootState} state - The current Redux store state.
 * @returns {AddressStatsPointFeature[]} An array of address stats point features that are not part of the mission addresses and have a valid address.
 */

export const selectFilteredSortingPoints = createSelector(
  [
    (state: RootState) => selectAllSortingPoints(state),
    (state: RootState) => state.missions.missionAddresses.entities,
  ],
  (sortingMapEntities, missionAddressesEntities) => {
    const missionAddressesKeySet = new Set<string>(
      Object.values(missionAddressesEntities)
        .filter((address) => address !== undefined)
        .map((address) => address!.addressKey)
    );

    return Object.values(sortingMapEntities).filter(
      (point): point is AddressStatsPointFeature => {
        if (!point) return false;

        const batchKey = getBatchKey(point.properties); // Returns addressKey

        return (
          !missionAddressesKeySet.has(batchKey) &&
          Boolean(point.properties.address.houseNumber) &&
          Boolean(point.properties.address.street)
        );
      }
    );
  }
);
