import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import MissionsMethods from 'controllers/mission';
import { useAppDispatch, useAppSelector, usePartnerID } from 'store/hooks';
import ActionButton from 'components/_include/Buttons/ActionButton';
import { Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface ConfirmDeleteMissionDialogProps { }

/**
 * A dialog that confirms the deletion of a mission.
 *
 * Displays a prompt and a button to confirm the action.
 * On confirmation, deletes the mission and then closes the dialog.
 */
const ConfirmDeleteMissionDialog: React.FC<ConfirmDeleteMissionDialogProps> = () => {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.MISSIONS]);
    const missionID = useAppSelector(state => state.missions.selectedMission.data?.ID);
    const missionName = useAppSelector(state => state.missions.selectedMission.data?.name);
    const partnerID = usePartnerID();
    const dispatch = useAppDispatch();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const navigate = useNavigate();

    /**
     * Handles the confirmation event to delete the mission.
     *
     * @returns {Promise<void>}
     */
    const onDeleteConfirmed = async () => {
        if (!partnerID || !missionID) return;

        const isDeleted = await dispatch(MissionsMethods.deleteMission(partnerID, missionID, missionName));
        if (isDeleted) {
            navigate(`/missions?partnerID=${partnerID}`);
        }
        setConfirmDialogOpen(false);
    };

    /**
     * Handles the click event to open the "delete mission" confirmation dialog.
     */
    const onConfirmDeleteClick = () => {
        setConfirmDialogOpen(true);
    };

    /**
     * Handles the click event to close the "delete mission" confirmation dialog.
     */
    const onCancelDeleteClick = () => {
        setConfirmDialogOpen(false);
    };

    return (
        <>
            <ActionButton startIcon={<Delete />} onClick={onConfirmDeleteClick} color="error">
                {t('delete', { ns: Namespace.ACTIONS })}
            </ActionButton>
            <Dialog open={confirmDialogOpen} onClose={onCancelDeleteClick}>
                <DialogTitle>
                    {t('confirm_delete_title', { ns: Namespace.MISSIONS, name: missionName })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('confirm_delete_message', { ns: Namespace.MISSIONS, name: missionName })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={onCancelDeleteClick}>
                        {t('cancel', { ns: Namespace.ACTIONS })}
                    </Button>
                    <Button color="error" onClick={onDeleteConfirmed}>
                        {t('confirm', { ns: Namespace.ACTIONS })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmDeleteMissionDialog;
