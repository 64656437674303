import { Card, CardContent, Grid } from '@mui/material';
import MoodSection from './MoodSection';
import TopicsSection from './TopicSection';
import ResidentsSpokenSection from './ResidentSpokenSection';
import { MoodStats, ReportStats, ResponsivenessLevel } from 'models/Missions';


type ReportStatsCard = {
    aggregatedStats: ReportStats;
}

const ReportStatsCard: React.FC<ReportStatsCard> = ({ aggregatedStats }) => {

    // Define the type for receptiveCounts
    type ReceptiveCountsType = {
        [key in ResponsivenessLevel]: number;
    };

    // Provide a default value with all enum keys
    const defaultReceptiveCounts: ReceptiveCountsType = {
        [ResponsivenessLevel.Responsive]: 0,
        [ResponsivenessLevel.Neutral]: 0,
        [ResponsivenessLevel.NotResponsive]: 0,
    };

    // Assign receptiveCounts with explicit type and default value
    const receptiveCounts: ReceptiveCountsType =
        aggregatedStats.inhabitantsReceptiveCounts ?? defaultReceptiveCounts;

    // Access the counts
    const happyCount = receptiveCounts[ResponsivenessLevel.Responsive];
    const neutralCount = receptiveCounts[ResponsivenessLevel.Neutral];
    const sadCount = receptiveCounts[ResponsivenessLevel.NotResponsive];
    const totalMoodCount = happyCount + neutralCount + sadCount;

    // Helper function to calculate percentages
    const calculatePercentage = (count: number, total: number): number =>
        total > 0 ? Math.round((count / total) * 100) : 0;

    // Calculate percentage for each mood using the enum
    const moodStats: MoodStats = {
        [ResponsivenessLevel.Responsive]: calculatePercentage(happyCount, totalMoodCount),
        [ResponsivenessLevel.Neutral]: calculatePercentage(neutralCount, totalMoodCount),
        [ResponsivenessLevel.NotResponsive]: calculatePercentage(sadCount, totalMoodCount),
    };

    // Calculate the spoken percentage
    const inhabitantsSpokenCount = aggregatedStats.inhabitantsSpokenCount!;
    const totalAddressesVisited = aggregatedStats.totalAddressesVisited;
    const spokenPercentage = totalAddressesVisited > 0
        ? Math.round((inhabitantsSpokenCount / totalAddressesVisited) * 100)
        : 0;

    return (
        <Card elevation={0} sx={{ width: '100%', border: '1px solid', borderColor: 'grey.300', borderRadius: 5 }}>
            <CardContent sx={{ paddingY: 4, paddingX: 5 }}>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item sm={12} md={6} lg={4}>
                        <MoodSection moodStats={moodStats} />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <TopicsSection
                            selectedTopics={aggregatedStats.selectedTopicsCounts!}
                            maxValue={totalAddressesVisited}
                        />
                    </Grid>
                    <Grid item sm={12} md={12} lg={4}>
                        <ResidentsSpokenSection percentageVisited={spokenPercentage} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};


export default ReportStatsCard;
