import { DEFAULT_ZOOM_LEVEL, GRENOBLE_COORDINATES } from "helpers/geo";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export interface AreasMapContextInterface {
    defaultCenter: google.maps.LatLngLiteral;
    defaultBounds: google.maps.LatLngBoundsLiteral | undefined;
    defaultZoom: number;
};

const initialState: AreasMapContextInterface = {
    defaultCenter: GRENOBLE_COORDINATES, // Ensure this is of type LatLngLiteral
    defaultBounds: {
        north: 0,
        south: 0,
        east: 0,
        west: 0,
    },
    defaultZoom: DEFAULT_ZOOM_LEVEL,
};


// Create the slice with updated action payload types
export const areasMapSlice = createSlice({
    name: 'areasMap',
    initialState: initialState,
    reducers: {
        setMapBounds: (state, { payload: { center, bounds } }: PayloadAction<{ center: google.maps.LatLngLiteral; bounds: google.maps.LatLngBoundsLiteral }>) => {
            state.defaultCenter = center;
            state.defaultBounds = bounds;
        },
        setCenterAndZoom: (state, { payload: { center, zoom } }: PayloadAction<{ center: google.maps.LatLngLiteral; zoom: number }>) => {
            state.defaultCenter = center;
            state.defaultZoom = zoom;
        },
        resetMapBounds: (state) => {
            state.defaultBounds = undefined;
        },
    },
});

export const AreasMapActions = areasMapSlice.actions;

const AreasMapReducer = areasMapSlice.reducer;

export default AreasMapReducer;