import { CircularProgress, Box, Theme, useTheme, darken, SimplePaletteColorOptions, } from '@mui/material';
import { SxProps } from '@mui/system';
import { CSSProperties, HTMLAttributeAnchorTarget, MouseEventHandler, ReactNode } from 'react';
import IconActionButton from './IconActionButton';
import LabelActionButton from './LabelActionButton';

const GradientButtonSx: SxProps<Theme> = {
    color: "white.main",
    fontWeight: "bold",
    backgroundColor: theme => theme.palette.primary.main,
    backgroundImage: theme => `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 50%)`,
    backgroundSize: `calc(100% + 100px)`,
    transition: `background-position .5s`,
    "&:hover": {
        backgroundPosition: "100%",
    },
};

export type OnClickAction = {
    type?: "button" | "submit" | "reset";
    onClick: MouseEventHandler | undefined;
};

export type LabelButtonProps = {
    component: "label";
}

type LinkAction = {
    href: string;
    target?: HTMLAttributeAnchorTarget;
};

type DownloadAction = {
    download: string;
}

type ButtonActions = OnClickAction | LinkAction | DownloadAction | LabelButtonProps;

export type ActionButtonProps = {
    id?: string;
    children?: any;
    color: "white" | "primary" | "secondary" | "gradient" | "success" | "error" | "warning" | undefined;
    disabled?: boolean;
    loading?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
    style?: CSSProperties;
    fullWidth?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    icon?: ReactNode;
    size?: "normal" | "compact";
} & Partial<ButtonActions>;

const getTransitionString = (cssProp: string) => `${cssProp} 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`;

function ActionButton(props: ActionButtonProps) {

    const { id, children, color, disabled, loading, style, fullWidth, startIcon, endIcon, icon, type, size, ...action } = props;

    const buttonDisabled = disabled || loading;

    const theme = useTheme();

    const getButtonColor = () => {
        switch (color) {
            case "gradient":
            case undefined:
                return undefined;

            case "white":
                return "inherit";

            default:
                return color;
        }
    };

    let buttonStyle: SxProps<Theme> = {};
    if (color === "gradient") { // gradient button
        if (!buttonDisabled) {
            buttonStyle = { ...GradientButtonSx };
        }
    }
    else { // theme palette or white color
        if (color !== undefined) {
            const shadowColor = color === "white" ? "rgb(150,150,150)" : darken(theme.palette[color].main, 0.3);
            const hoverBgColor = color === "white" ? "#ccc" : darken(theme.palette[color].main, 0.1);
            buttonStyle = {
                boxShadow: `0px 4px ${shadowColor}`,
                "&:hover, &:active": {
                    boxShadow: `0px 4px ${shadowColor}`,
                    bgcolor: hoverBgColor,
                },
                "&:active": {
                    boxShadow: `0px 0px ${shadowColor}`,
                    mt: "4px",
                    mb: "-4px",
                },
                color: color === "white" ? "#333" : "#ffffff",
                transition: `${getTransitionString("background-color")}, ${getTransitionString("box-shadow")}, ${getTransitionString("border-color")}, ${getTransitionString("color")}, ${getTransitionString("margin")}`,
            };

            // background color
            if (color === "white") {
                buttonStyle.bgcolor = "#eee";
            }
            else if (color) {
                buttonStyle.bgcolor = (theme.palette[color] as SimplePaletteColorOptions).main;
            }
        }
    }

    const onDownload = () => {
        if ("download" in action && action.download) {
            const link = document.createElement("a");
            const downloadURIElements = action.download.split("/");
            link.download = downloadURIElements[downloadURIElements.length - 1];
            link.href = `./${action.download}`;
            link.click();
        }
    };

    return (
        <Box
            style={{
                position: "relative",
                textAlign: "center",
                ...style,
                ...(fullWidth && { width: "100%" })
            }}
        >
            {icon ? ( // icon button
                <IconActionButton
                    id={id}
                    disabled={buttonDisabled}
                    type={type}
                    onClick={"onClick" in action ? action.onClick : "download" in action ? onDownload : undefined}
                    buttonStyle={buttonStyle}
                    color={getButtonColor()}
                    size={size}
                    {...action}
                >
                    {icon}
                </IconActionButton>
            ) : ( // normal button with label
                <LabelActionButton
                    id={id}
                    disabled={buttonDisabled}
                    type={type}
                    onClick={"onClick" in action ? action.onClick : "download" in action ? onDownload : undefined}
                    href={"href" in action ? action.href : undefined}
                    target={"target" in action ? action.target : undefined}
                    startIcon={startIcon}
                    endIcon={endIcon}
                    fullWidth={fullWidth}
                    buttonStyle={buttonStyle}
                    color={getButtonColor()}
                    size={size}
                    {...action}
                >
                    {children}
                </LabelActionButton>
            )}

            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -1.5,
                        marginLeft: -1.5,
                    }}
                />
            )}
        </Box>
    );
}

export default ActionButton;