import { Box } from "@mui/material";
import MapHelpSpeedDial from "components/_include/Maps/MapHelpSpeedDial/MapHelpSpeedDial";
import { Outlet } from "react-router-dom";
import { useIsAdmin, usePartnerID } from "store/hooks";
import SectionLoader from "../_include/SectionLoader";
import BatchesDrawer from "./SortingMap/DetailsDrawer/BatchesDrawer";
import SortingMapFilters from "./SortingMap/Filters/SortingMapFilters";
import PlacesListDrawer from './SortingMap/PlacesListDrawer/PlacesListDrawer';
import SortingMapLayout from "./SortingMap/SortingMapLayout";

type SortingMapPageProps = {
    showForMissions: boolean;
}

function SortingMapPage({ showForMissions }: SortingMapPageProps) {

    const isAdmin = useIsAdmin();
    const partnerID = usePartnerID();

    return (
        !partnerID && !isAdmin ? (
            <SectionLoader />
        ) : ( // partnerID loaded or admin
            <Box
                width={showForMissions ? `100%` : "100%"}
                height={showForMissions ? `500px` : "100vh"}
                position="relative"
            >
                <SortingMapLayout showForMissions={showForMissions} />
                {!showForMissions && (
                    <Box
                        position="absolute"
                        top={(theme) => theme.spacing(1)}
                        left={(theme) => theme.spacing(1)}
                        right={(theme) => theme.spacing(1)}
                        height={(theme) => `calc(100% - ${theme.spacing(2)})`}
                        display="flex"
                        flexDirection="row"
                        justifyContent="stretch"
                        sx={{
                            pointerEvents: "none",
                        }}
                    >

                        <PlacesListDrawer  />
                        <SortingMapFilters />
                        <MapHelpSpeedDial />
                        <BatchesDrawer />
                    </Box>
                )}
                <Outlet />
            </Box>

        )
    )
};

export default SortingMapPage;
