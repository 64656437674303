import { Chip, Typography } from "@mui/material";
import { Namespace } from "locales/translations";
import { MISSION_ADDRESS_COLOR_MAPPING, MissionAddressStatus } from "models/Missions"
import { useTranslation } from "react-i18next";

type ChipProps = {
    status: MissionAddressStatus;
}

/**
 * Chip used in the mission's addresses table to describe the current status of the address.
 */
export default function MissionAddressStatusChip({ status }: ChipProps) {
    const { t } = useTranslation([Namespace.MISSIONS]);

    const color = MISSION_ADDRESS_COLOR_MAPPING[status];

    const label = t(status, { ns: Namespace.MISSIONS });

    return (
        <Chip
            sx={{
                width: '100%',
            }}
            color={color}
            label={
                <Typography
                    variant='body2'
                    sx={{
                        fontWeight: 500,
                        color: '#ffff',
                    }}
                >
                    {label}
                </Typography>
            }
        />
    )
}