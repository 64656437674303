import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SensitizationAddresses } from "models/Missions";

type SelectedMissionAddressContext = {
    address: string | null;
    report: SensitizationAddresses["report"];
};

const initialState: SelectedMissionAddressContext = {
    address: null,
    report: undefined,
};

export const selectedMissionAddressSlice = createSlice({
    name: 'selected_mission_address',
    initialState,
    reducers: {
        setSelected: (state, { payload: { address, report } }: PayloadAction<SelectedMissionAddressContext>) => {
            state.address = address;
            state.report = report;
        },
    },
});

export const SelectedMissionAddressActions = selectedMissionAddressSlice.actions;

const SelectedMissionAddressReducer = selectedMissionAddressSlice.reducer;

export default SelectedMissionAddressReducer;
