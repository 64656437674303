import { Box, darken, Tooltip, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AdvancedMarker, Marker, useAdvancedMarkerRef, useMarkerRef } from "@vis.gl/react-google-maps";
import { DANGER_COLORS } from "helpers/draw";
import { DangerLevel } from "constants/stats";
import { getCustomIcon, getMarkerZIndex } from "./MarkerIcon";
import MapDotMarker from "./MapDotMarker";

type MapMarkerProps = {
    lat: number;
    lng: number;
    id: string; // batchID or addressKey
    dangerLevel: DangerLevel;
    selected?: boolean;
    hovered?: boolean;
    onClick: (id: string) => void;
    mapInstance: google.maps.Map | null;
    panoramaInstance: google.maps.StreetViewPanorama | null;
    isAddressMarker?: boolean; // Flag to identify address markers
    address?: string; // Only for address markers
};

const MARKER_SIZES = {
    default: 15,
    selected: 20,
    hovered: 18,
    streetView: 100,
    streetViewSelected: 150,
};

const MAX_OPACITY_DISTANCE = 20; // Full opacity within 20 meters
const MIN_OPACITY_DISTANCE = 100; // Minimum opacity beyond 100 meters

export default function MapMarker(props: MapMarkerProps) {
    const {
        lat,
        lng,
        id,
        dangerLevel,
        selected = false,
        hovered = false,
        onClick,
        mapInstance,
        panoramaInstance,
        isAddressMarker = false,
        address = "",
    } = props;

    const theme = useTheme();
    const emphased = selected || hovered;
    const markerColor = selected ? theme.palette.info.main : DANGER_COLORS[dangerLevel];

    const [markerSize, setMarkerSize] = useState(MARKER_SIZES.default);
    const [streetViewVisible, setStreetViewVisible] = useState(false);
    const [streetViewPosition, setStreetViewPosition] = useState<google.maps.LatLng | null>(null);
    const [markerOpacity, setMarkerOpacity] = useState(1);

    const handleMarkerClick = useCallback(() => {
        onClick(id);

        if (streetViewVisible && panoramaInstance) {
            // Move the panorama to the marker's location
            panoramaInstance.setPosition({ lat, lng });

            // Adjust the panorama's POV (heading and pitch) towards the marker
            const pov = panoramaInstance.getPov();
            const currentPosition = panoramaInstance.getPosition();

            if (currentPosition) {
                const heading = google.maps.geometry.spherical.computeHeading(
                    currentPosition,
                    new google.maps.LatLng(lat, lng)
                );

                panoramaInstance.setPov({
                    heading: heading,
                    pitch: pov.pitch,
                });
            }
        }
    }, [id, lat, lng, onClick, panoramaInstance, streetViewVisible]);

    // Update marker size based on context
    useEffect(() => {
        if (streetViewVisible) {
            setMarkerSize(emphased ? MARKER_SIZES.streetViewSelected : MARKER_SIZES.streetView);
        } else if (emphased) {
            setMarkerSize(MARKER_SIZES.selected);
        } else if (hovered) {
            setMarkerSize(MARKER_SIZES.hovered);
        } else {
            setMarkerSize(MARKER_SIZES.default);
        }
    }, [emphased, hovered, streetViewVisible]);

    // Marker references
    const [markerRef, markerInstance] = useMarkerRef();
    const [advancedMarkerRef, advancedMarkerInstance] = useAdvancedMarkerRef();

    // Manage Street View visibility and position
    useEffect(() => {
        if (panoramaInstance) {
            const visibilityChangedListener = panoramaInstance.addListener(
                "visible_changed",
                () => {
                    setStreetViewVisible(panoramaInstance.getVisible());
                    if (panoramaInstance.getVisible()) {
                        setStreetViewPosition(panoramaInstance.getPosition() || null);
                    } else {
                        setStreetViewPosition(null);
                    }
                }
            );

            if (isAddressMarker) { // set steetview back button to bottom on sorting map
                panoramaInstance.setOptions({
                    addressControl: true,
                    fullscreenControl: false,
                    addressControlOptions: {
                        position: google.maps.ControlPosition.BOTTOM_CENTER,
                    },
                });
            }

            const positionChangedListener = panoramaInstance.addListener(
                "position_changed",
                () => {
                    if (panoramaInstance.getVisible()) {
                        setStreetViewPosition(panoramaInstance.getPosition() || null);
                    }
                }
            );

            // Clean up the event listeners
            return () => {
                google.maps.event.removeListener(visibilityChangedListener);
                google.maps.event.removeListener(positionChangedListener);
            };
        }
    }, [isAddressMarker, panoramaInstance]);

    // Update marker placement based on Street View visibility
    useEffect(() => {
        if (markerInstance) {
            markerInstance.setMap(streetViewVisible ? panoramaInstance : null);
        }
    }, [markerInstance, streetViewVisible, panoramaInstance]);

    // For advanced marker usage on the normal map
    useEffect(() => {
        if (advancedMarkerInstance) {
            advancedMarkerInstance.map = !streetViewVisible ? mapInstance : null;
        }
    }, [advancedMarkerInstance, mapInstance, streetViewVisible]);

    // Compute marker opacity based on distance from Street View location
    useEffect(() => {
        if (streetViewVisible && streetViewPosition) {
            const markerPosition = new google.maps.LatLng(lat, lng);
            const distance = google.maps.geometry.spherical.computeDistanceBetween(
                streetViewPosition,
                markerPosition
            );

            if (distance <= MAX_OPACITY_DISTANCE) {
                setMarkerOpacity(1);
            } else if (distance >= MIN_OPACITY_DISTANCE) {
                setMarkerOpacity(0.1);
            } else {
                // Linear interpolation between 1 (near) and 0.1 (far)
                const opacity =
                    1 -
                    ((distance - MAX_OPACITY_DISTANCE) / (MIN_OPACITY_DISTANCE - MAX_OPACITY_DISTANCE)) * 0.9;
                setMarkerOpacity(opacity);
            }
        } else {
            // Normal map or Street View not active
            setMarkerOpacity(1);
        }
    }, [streetViewVisible, streetViewPosition, lat, lng]);

    // Get the custom icon
    const customIcon = getCustomIcon(markerColor, markerSize, markerOpacity);

    // Rendering the marker content for the advanced marker on the normal map
    const markerContent = useMemo(() => {
        const content = (
            <Box
                width={markerSize}
                height={markerSize}
                mt={`${-markerSize / 2}px`}
                ml={`${-markerSize / 2}px`}
                borderRadius="50%"
                bgcolor={markerColor}
                border={`1px solid ${darken(markerColor, 0.2)}`}
                boxShadow={1}
            />
        );

        if (isAddressMarker && address) {
            return (
                <Tooltip title={address} arrow>
                    <MapDotMarker color={markerColor} size={markerSize} zIndex={getMarkerZIndex(dangerLevel)} />
                </Tooltip>
            );
        }

        return content;
    }, [markerSize, markerColor, isAddressMarker, address, dangerLevel]);

    return streetViewVisible ? (
        // Render the normal <Marker> for Street View
        <Marker
            position={{ lat, lng }}
            onClick={handleMarkerClick}
            ref={markerRef}
            icon={customIcon}
        />
    ) : (
        // Render the <AdvancedMarker> on the normal map
        <AdvancedMarker
            position={{ lat, lng }}
            onClick={handleMarkerClick}
            ref={advancedMarkerRef}
        >
            {markerContent}
        </AdvancedMarker>
    );
}