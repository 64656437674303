import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Area from "models/Area";
import { LoadableContext } from "store/store";

type SelectedAreaContext = LoadableContext & {
    data: Area | null;
    editing: boolean;
    newPath: google.maps.LatLngLiteral[] | null;
};

const initialState: SelectedAreaContext = {
    loading: false,
    error: null,
    data: null,
    editing: false,
    newPath: null,
};


export const selectedAreaSlice = createSlice({
    name: 'selected_area',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        selectArea: (state, { payload: { area, editing } }: PayloadAction<{ area: Area | null, editing?: boolean }>) => {
            state.loading = false;
            state.data = area;
            if (editing !== undefined) state.editing = editing;
        },
        update: (state, { payload: data }: PayloadAction<Partial<Area>>) => {
            state.loading = false;
            if (state.data) {
                state.data = {
                    ...state.data,
                    ...data,
                };
            }
            state.editing = false;
            state.newPath = null;
        },
        removeLast: (state) => {
            if (state.newPath && state.newPath.length > 0) { // there are points in path
                state.newPath = state.newPath.slice(0, state.newPath.length - 1);
            }
        },
        setEditing: (state, { payload: editing }: PayloadAction<boolean>) => {
            state.editing = editing;
        },
        addPointToNewPath: (state, { payload: point }: PayloadAction<google.maps.LatLngLiteral>) => {
            state.editing = true;
            if (!state.newPath) state.newPath = [];
            state.newPath?.push(point);
        },
        setNewPath: (state, { payload: path }: PayloadAction<google.maps.LatLngLiteral[] | null>) => {
            state.editing = path !== null;
            state.newPath = path;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SelectedAreaActions = selectedAreaSlice.actions;

const SelectedAreaReducer = selectedAreaSlice.reducer;

export default SelectedAreaReducer;