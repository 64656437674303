import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector, useMapCamera, usePanorama } from "store/hooks";
import { getBoundsZoomLevel } from "helpers/geo";
import { BatchesActions } from "store/reducers/batches/list";
import BatchMarker from "./BatchMarker";
import { MapWrapper } from "components/_include/Maps/MapWrapper";

type BatchesMapProps = {
    width: number | undefined;
    height: number | undefined;
}

export default function BatchesMap(props: BatchesMapProps) {

    const { width, height } = props;

    const defaultCenter = useAppSelector(state => state.batches.collectionMap.defaultCenter);
    const defaultBounds = useAppSelector(state => state.batches.collectionMap.defaultBounds);
    const defaultZoom = useAppSelector(state => state.batches.collectionMap.defaultZoom);

    const dispatch = useAppDispatch();


    const INITIAL_CAMERA = {
        center: defaultCenter,
        zoom: defaultZoom
    };

    const { cameraProps, handleCameraChange, setCameraProps } = useMapCamera(INITIAL_CAMERA);
    const { map, panoramaInstance } = usePanorama();
    const points = useAppSelector(state => state.batches.collectionMap.points);

    useEffect(() => {
        if (defaultBounds && width && height) {
            const mapDims = {
                width: width,
                height: height,
            };
            let newZoom = getBoundsZoomLevel(defaultBounds, mapDims);

            setCameraProps({ center: defaultCenter, zoom: newZoom })
        }
    }, [defaultBounds, defaultCenter, height, setCameraProps, width]);

    const handleBatchMarkerClick = useCallback((batchID: string) => {
        dispatch(BatchesActions.selectBatch(batchID));
    }, [dispatch]);


    return (
        <MapWrapper mapId="defaultMap" initialCamera={cameraProps} onCameraChanged={handleCameraChange} mapTypeControlOptions={{
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.BOTTOM_CENTER,
            mapTypeIds: [
                google.maps.MapTypeId.ROADMAP,
                google.maps.MapTypeId.SATELLITE,
            ],
        }
        }>
            {points.map((point) => {
                const {
                    batchID,
                    position,
                    dangerLevel,
                } = point;

                return (
                    <BatchMarker
                        key={batchID}
                        lat={position.lat}
                        lng={position.lng}
                        batchID={batchID}
                        dangerLevel={dangerLevel}
                        onClick={handleBatchMarkerClick}
                        mapInstance={map}
                        panoramaInstance={panoramaInstance}
                    />
                );
            })}
        </MapWrapper>
    );
}