const serverURL = process.env.REACT_APP_SERVER_URL;
const httpFunctionBaseURL = process.env.REACT_APP_HTTP_FUNCTIONS_BASE_URL;
const googleMapsWebsite = "https://www.google.com/maps";

export default {
    API: `${serverURL}`,
    HTTP_FUNCTIONS: {
        baseURL: httpFunctionBaseURL,
        listPartners: `${httpFunctionBaseURL}/httpListPartners`,
        staticMapImage: `${httpFunctionBaseURL}/httpGetStaticMap`,
    },
    REPORTS_API: `${process.env.REACT_APP_REPORTS_SERVICE}/v1`,
    STORAGE: "https://storage.googleapis.com",
    WEBSITE: "https://ficha.fr",
    GOOGLE_MAPS: {
        WEBSITE: googleMapsWebsite,
        PLACE: `${googleMapsWebsite}/place`,
    },
    FEEDBACK_FORM: "https://docs.google.com/forms/d/e/1FAIpQLSeglxyzUUVw4Gq_jQIE8DqDBVxYvWemSPGqyyZbRG0Ru15yOA/viewform",
    ICONS_FOLDER: `${process.env.PUBLIC_URL}/icons`,
    IMAGES_FOLDER: `${process.env.PUBLIC_URL}/images`,
    FONTS_FOLDER: `${process.env.PUBLIC_URL}/fonts`,
}

/** Possible keys for URL search parameters. */
export enum SearchParam {
    /** Selected waste stream / collection type. */
    WASTE_STREAM = "stream",

    /** Sorting errors selected for filtering. */
    SELECTED_ERRORS = "errors",

    /** Minimum number of errors made at each address. */
    MIN_ERRORS_COUNT = "minErrorsCount",

    /** Selected truck ID. Leave empty to select all trucks. */
    TRUCK_ID = "truckID",

    /** Selected area ID. Leave empty to select all areas. */
    AREA_ID = "areaID",

    /** Selected itinerary ID. Leave empty to select all itineraries. */
    ROUTE_ID = "routeID",

    /** Type of predefined or custom range for the selected dates. @see DatesRangeType */
    DATES_RANGE_TYPE_SELECT = "dateSelect",

    /** Start of the selected date range. Format should be YYYY-MM-DD. */
    START_DATE = "startDate",

    /** End of the selected date range. Format should be YYYY-MM-DD. */
    END_DATE = "endDate",

    /** Start time of the selected date range. Format should be HH:mm. */
    START_TIME = "startTime",

    /** End time of the selected date range. Format should be HH:mm. */
    END_TIME = "endTime",
}