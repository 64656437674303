import { Typography } from "@mui/material";
import { TFunction } from "i18next";
import { Namespace } from "locales/translations";
import { BinType, BinsCounts } from "models/Batch"
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type BinsCountsLabelProps = {
    binsCounts: BinsCounts;
}

export const formatBinsCounts = (t: TFunction, binsCounts: BinsCounts) => {
    let positionedBinsString = "";

    for (let binType of Object.values(BinType)) { // for each type of bin
        if ((binsCounts[binType] ?? 0) > 0) { // if there is at least one bin of this type
            if (positionedBinsString.length > 0) positionedBinsString += ", ";
            positionedBinsString +=
                `${t(`bins_count.${binType}`, {
                    ns: Namespace.COLLECTIONS,
                    count: binsCounts[binType],
                })}`;
        }
    }

    return `${t("bins_count.raised", { ns: Namespace.COLLECTIONS, count: Object.values(binsCounts).reduce((total, count) => total + count, 0), })
        } (${positionedBinsString})`;
};

export default function BinsCountsLabel({ binsCounts }: BinsCountsLabelProps) {
    const { t } = useTranslation([Namespace.COLLECTIONS]);

    const binsCountsString = useMemo(() => formatBinsCounts(t, binsCounts), [t, binsCounts.left, binsCounts.right, binsCounts.large]);

    return (
        <Typography
            variant="body2"
            sx={{
                opacity: 0.9,
            }}
        >
            {binsCountsString}
        </Typography>
    );
}