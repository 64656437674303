import { getAuth } from 'firebase/auth';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { UserRole } from 'models/User';
import { DependencyList, EffectCallback, useCallback, useEffect, useMemo, useState } from 'react';
import { MapCameraProps, MapCameraChangedEvent, useMap } from '@vis.gl/react-google-maps';

/** Get a dispatch function to modify the Redux store. */
export const useAppDispatch = () => useDispatch<AppDispatch>();

/** Access nested values from the Redux store. */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/** Get the ID of the currently selected partner. */
export const usePartnerID = () => useAppSelector(state => getAuth().currentUser === null ? undefined : state.partners.selected.data?.ID || state.user.partnerID);

/** Get the sorting errors preferences for the current partner. */
export const useSortingRules = () => {
    const errorsClasses = useAppSelector(state => state.sortingRules.errorsClasses);
    const mergingMapping = useAppSelector(state => state.sortingRules.mergingMapping);
    const displayedErrors = useAppSelector(state => state.sortingRules.displayedErrors);

    // Memoize the object combining the individual values
    return useMemo(() => ({
        errorsClasses,
        mergingMapping,
        displayedErrors,
    }), [errorsClasses, mergingMapping, displayedErrors]);
};


/** Get the status of the currently selected partner. */
export const usePartnerStatus = () => useAppSelector(state => state.partners.selected.data?.status);

/** 
 * True if current user is a Ficha admin and visualizing the dashboard as such, 
 * false if not a Ficha admin or seeing the data as a user.
 */
export const useIsAdmin = () => useAppSelector(state => state.user.role === UserRole.ADMIN && !state.user.viewAsPartner);

/**
 * True if the current user has been added to at least 1 partner, false if none.
 * Returns undefined while user hasn't been loaded.
 */
export const useHasPartner = () => useAppSelector(state => {
    if (state.user.email === null) return undefined; // user not loaded yet
    if (state.user.role === UserRole.ADMIN) return true; // admins can access all partners
    return Object.keys(state.user.partners ?? {}).length > 0;
});

/** 
 * True if current user has permission to modify data on a given partner (Ficha or partner admin).
 */
export const useCanWrite = (partnerID?: string) => useAppSelector(state => {
    // can write if Ficha admin
    if (state.user.role == UserRole.ADMIN) return true;

    // can write if admin for this given partner
    if (partnerID && state.user.partners && [UserRole.PARTNER_ADMIN, UserRole.PARTNER].includes(state.user.partners[partnerID])) return true;

    return false;
});

/**
 * Hook that will wait to perform a useEffect until the state hasn't updated for the duration of the delay.
 * @param effect The callback to execute.
 * @param deps Array of depencies for the effect.
 * @param delay Delay to wait without any update to the state in milliseconds.
 */
export const useDebouncedEffect = (effect: EffectCallback, deps: DependencyList, delay: number) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);
        return () => clearTimeout(handler);
    }, [...(deps || []), delay]);
}

/**
 * This hook will manage the camera's position (center) and zoom level (zoom) and provide a handler for camera changes.
 * @param initialCamera The initial map's camera position state which contains the center and zoom for the map.
 */
export function useMapCamera(initialCamera: MapCameraProps) {
    const [cameraProps, setCameraProps] = useState<MapCameraProps>(initialCamera);

    const handleCameraChange = useCallback((ev: MapCameraChangedEvent) => {
        const { center, zoom } = ev.detail;
        setCameraProps({ center, zoom });
    }, []);

    return { cameraProps, handleCameraChange, setCameraProps };
}

/**
 * This hook will manage the Street View panorama instance, which is used in multiple components.
 */
export function usePanorama() {
    const map = useMap();
    const [panoramaInstance, setPanoramaInstance] = useState<google.maps.StreetViewPanorama | null>(null);

    useEffect(() => {
        if (map) {
            const panorama = map.getStreetView();
            setPanoramaInstance(panorama);
        }
    }, [map]);

    return { map, panoramaInstance };
}