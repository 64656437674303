import React, { useMemo } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Theme, Typography, useTheme } from '@mui/material';
import { SentimentNeutral, SentimentVeryDissatisfied, SentimentSatisfiedAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';

export enum ResponsivenessLevel {
    Responsive = 'responsive',
    Neutral = 'neutral',
    NotResponsive = 'not_responsive',
}

type MoodSelectorProps = {
    question: string;
    value: ResponsivenessLevel | null;
};

const getMoodIcons = (theme: Theme) => [
    {
        value: ResponsivenessLevel.Responsive,
        icon: <SentimentSatisfiedAlt fontSize="inherit" />,
        color: theme.palette.success.main,
    },
    {
        value: ResponsivenessLevel.Neutral,
        icon: <SentimentNeutral fontSize="inherit" />,
        color: theme.palette.warning.main,
    },
    {
        value: ResponsivenessLevel.NotResponsive,
        icon: <SentimentVeryDissatisfied fontSize="inherit" />,
        color: theme.palette.error.main,
    },
];

const MoodSelector: React.FC<MoodSelectorProps> = ({ question, value }) => {
    const { t } = useTranslation([Namespace.MISSION_REPORT]);
    const theme = useTheme();
    const moodIcons = useMemo(() => getMoodIcons(theme), [theme]);

    return (
        <FormControl component="fieldset" sx={{ mb: 3 }}>
            <Typography variant="subtitle1">{t(question, { ns: Namespace.MISSION_REPORT })}</Typography>
            <RadioGroup
                row
                value={value}
                sx={{ display: 'flex', gap: 2, p: 1 }}
            >
                {moodIcons.map((mood) => (
                    <FormControlLabel
                        key={mood.value}
                        value={mood.value}
                        control={<Radio disabled={value !== mood.value} sx={{ display: 'none' }} />} // Hide default radio button
                        label={
                            <Box
                                sx={{
                                    color: value === mood.value ? mood.color : 'grey',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '60px',
                                    transition: 'color 0.3s',
                                }}
                            >
                                {mood.icon}
                            </Box>
                        }
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default MoodSelector;