import { createBreakpoints } from "@mui/system";
import { createTheme, PaletteColorOptions, PaletteOptions } from '@mui/material';
import { CompleteTheme } from "@nivo/core";
import { CollectionType } from "models/Collection";
import { amber, grey } from "@mui/material/colors";

export const ERRORS_BUTTONS_SPACING_TRANSLATION = 12;
export const ERRORS_BUTTONS_SPACING_WIDTH = 32;

declare module '@mui/material/styles' {
    interface Palette {
        white: PaletteColorOptions;
        yellow: PaletteColorOptions;
        red: PaletteColorOptions;
    }
    interface PaletteOptions {
        white: PaletteColorOptions;
        yellow: PaletteColorOptions;
        red: PaletteColorOptions;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        white: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        white: true;
        black: true;
    }
}

declare module '@mui/material/Fab' {
    interface FabPropsColorOverrides {
        white: true;
    }
}

declare module '@mui/material/Checkbox' {
    interface CheckboxPropsColorOverrides {
        white: true;
    }
}

const breakpoints = createBreakpoints({});

const SPACING = 8;

export const useSpacing = (value: number) => SPACING * value;

const spacing = (value: number) => `${SPACING * value}px`;

/** Width of the main navigation drawer. */
export const DRAWER_WIDTH = spacing(25);

const palette: PaletteOptions = {
    primary: {
        main: '#FF3399',
        dark: '#B2236B',
        light: '#FFC0DD',
    },
    secondary: {
        main: '#336699',
    },
    success: {
        light: '#66CC88',
        main: '#5FB474',
        dark: '#228844',
    },
    warning: {
        light: '#ffb74d',
        main: '#ffa726',
        dark: '#f57c00',
    },
    error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
    },
    info: {
        main: '#55AAFF',
    },
    text: {
        secondary: "rgba(0, 0, 0, 0.9)",
        disabled: "rgba(0, 0, 0, 0.6)",
    },
    white: {
        main: "#ffffff"
    },
    yellow: {
        light: "#ffd453",
        main: "#ffca28",
        dark: "#b28d1c",
    },
    red: {
        dark: "#a73a38",
        main: "#ef5350",
        light: "#f27573",
    },
    background: {
        default: "#EFEFEF",
        paper: "#FFFFFF",
    },
};

const theme = createTheme({
    palette: palette,
    typography: {
        fontFamily: [
            "CircularStd", 
            "Roboto"
        ].join(','),
        h1: {
            fontWeight: 900,
            fontSize: '2rem',
            marginBottom: spacing(4),
            marginTop: spacing(4),
            [breakpoints.up('sm')]: { // not mobile
                fontSize: '2.5rem',
            },
        },
        h2: {
            fontWeight: 900,
            fontSize: '1.75rem',
            marginBottom: spacing(2),
            marginTop: spacing(2),
            color: "#222222"
        },
        h3: {
            fontWeight: 900,
            fontSize: '1.5rem',
            marginBottom: spacing(2),
            marginTop: spacing(2),
            color: "#222222"
        },
        h4: {
            fontWeight: 700,
            fontSize: '1.1rem',
            marginBottom: spacing(2),
            marginTop: spacing(2),
            [breakpoints.up('sm')]: { // not mobile
                fontSize: '1.375rem',
            },
            color: "#333333"
        },
        h5: {
            fontWeight: 700,
            fontSize: '1rem',
        },
        h6: {
            fontWeight: 700,
            fontSize: '0.875rem',
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: '1rem',
        },
        button: {
            fontWeight: 600,
            fontSize: '0.875rem',
            textTransform: "none",
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: '0.75rem',
        },
    },
    shape: {
        borderRadius: 4,
    },
    spacing: spacing,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@global": {
                    html: {
                        [breakpoints.down('sm')]: {
                            fontSize: 13,
                        }
                    }
                }
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    width: `calc(100% - ${DRAWER_WIDTH})`,
                    paddingTop: spacing(2),
                    paddingBottom: spacing(4),
                }
            },
        },
        MuiTypography: {
            styleOverrides: {
                h5: {
                    color: "#333333",
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    textTransform: 'none',
                    
                    "& .MuiLoadingButton-loadingIndicator .MuiCircularProgress-root": {
                        position: "relative"
                    }
                },
                startIcon: {
                    "& svg": {
                        fontSize: "1rem",
                    }
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    paddingBottom: spacing(2),
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    marginLeft: spacing(1),
                },
                indicator: {
                    height: 3,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    backgroundColor: "white",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    margin: '0 16px',
                    minWidth: 0,
                    padding: 0,
                    [breakpoints.up('md')]: {
                        padding: 0,
                        minWidth: 0,
                    },
                },
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: spacing(1),
                },
            },
            variants: [
                {
                    props: { color: "white" },
                    style: {
                        "& svg": {
                            color: '#ffffff',
                        },
                    },
                }
            ]
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 4,
                },
            },
        },
        MuiSpeedDialAction: {
            styleOverrides: {
                staticTooltipLabel: {
                    whiteSpace: "nowrap",
                }
            }
        },
        MuiDivider: {
            defaultProps: {
                light: true,
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    textDecoration: "none",
                    textDecorationStyle: "unset",
                }
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    // fontWeight: 500,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    marginRight: 0,
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
        MuiImageListItemBar: {
            styleOverrides: {
                subtitle: {
                    lineHeight: "1rem",
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "rgba(0,0,0,0.6)",
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: spacing(4),
                    height: spacing(4),
                },
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: "100%",
                }
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                }
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: "100%",
                }
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    position: "absolute",
                }
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    paddingTop: spacing(2),
                }
            }
        },
        MuiDialogTitle: {
            defaultProps: {
                variant: "h3",
            },
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    paddingBottom: spacing(3),
                    paddingRight: spacing(6),
                    paddingLeft: spacing(6),
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingBottom: spacing(4),
                    paddingRight: spacing(6),
                    paddingLeft: spacing(6),
                }
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingBottom: spacing(4),
                    paddingRight: spacing(6),
                    paddingLeft: spacing(6),

                    "& button": {
                        fontSize: "1rem",
                    }
                }
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                noOptions: {
                    fontStyle: "italic",
                    color: palette.text?.disabled,
                }
            }
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

export const PIE_CHART_THEME: Partial<CompleteTheme> = {
    labels: {
        text: {
            fontWeight: "bold",
        }
    }
};

export default theme;

export const getWasteStreamMainColor = (wasteStream: CollectionType | null) => {
    switch (wasteStream) {
        case CollectionType.BIOWASTE: return theme.palette.success.main;
        case CollectionType.RESIDUAL_WASTE: return grey[800];
        case CollectionType.SORTABLE_WASTE: return amber[500];
        default: return theme.palette.secondary.main;
    }
}

export const getWasteStreamFontColor = (wasteStream: CollectionType | null) => {
    if (wasteStream === CollectionType.SORTABLE_WASTE) return "rgba(0,0,0,.7)";
    return "#fff";
};
