import { Box, Container, Typography } from "@mui/material";
import PageTitle from "components/_include/PageTitle";
import ExplorerFilters from "./FiltersAndActions/ExplorerFilters";
import { SearchBatchesButton } from "./FiltersAndActions/SearchBatchesButton";
import { ExplorerDataGrid } from "./ExplorerDataGrid";
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";

export const LOADING_LIMIT = 9999;

/**
 * Page presenting raw data to the user, with possibility to filter, search and export.
 */
function ExplorerPage() {

    const { t } = useTranslation([Namespace.STATS]);

    return (
        <Container
            maxWidth="xl"
        >
            <PageTitle />

            <Typography 
                variant="subtitle1"
                fontStyle="italic"
                color="rgba(0,0,0,0.5)"
                marginBottom={2}
                >
                {t("explorer_intro", { ns: Namespace.STATS })}
            </Typography>

            <Box
                display="flex"
                flexDirection="row"
                mb={2}
            >
                <ExplorerFilters />

                <SearchBatchesButton loadingLimit={LOADING_LIMIT} />
            </Box>

            <ExplorerDataGrid />
        </Container>
    );
};

export default ExplorerPage;
