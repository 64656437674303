import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearchRounded";
import DeleteForeverIcon from "@mui/icons-material/DeleteForeverRounded";
import { CircularProgress, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector, usePartnerID } from "store/hooks";
import { AddressRowData } from "./MissionAddressesTable";
import MissionAddressesMethods from "controllers/sensitization_addresses";
import { useParams } from "react-router-dom";
import { SelectedMissionAddressActions } from "store/reducers/missions/addresses/selected";
import { formatAddress } from "helpers/geo";
import { MissionStatus } from "models/Missions";

type ButtonProps = {
    address: AddressRowData;
}

export default function MissionAddressActionButton({ address }: ButtonProps) {
    const loading = useAppSelector((state) => state.missions.missionAddresses.loading);
    
    const { missionID } = useParams();
    const partnerID = usePartnerID();

    const missionCompleted = useAppSelector(state => state.missions.selectedMission.data?.status === MissionStatus.COMPLETED);
    
    const dispatch = useAppDispatch();

    if (address.status.visited && !address.addressReport) return null;

    /** Open the dialog presenting the report made by the ambassador. */
    const openReport = () => {
        dispatch(SelectedMissionAddressActions.setSelected({ 
            address: formatAddress({ houseNumber: address.house, street: address.street, city: address.city }),
            report: address.addressReport,
        }));
    }

    /** Remove the address from the mission. */
    const removeAddress = () => {
        if (!missionID || !partnerID) {
            console.error('Mission ID or Partner ID is missing.');
            return;
        }

        dispatch(
            MissionAddressesMethods.deleteMissionAddress(
                partnerID,
                missionID,
                address.ID,
            )
        );
    };

    const handleIconButtonClick = () => {
        if (Boolean(address.addressReport)) { // open report made by ambassador
            openReport();
        } 
        else { // remove address from mission
            removeAddress();
        }
    };

    return (
        <IconButton
            aria-label="delete"
            color="default"
            onClick={handleIconButtonClick}
            disabled={loading}
        >
            {address.addressReport ? ( // show report ambassador made 
                <ContentPasteSearchIcon />
            ) : !missionCompleted && (
                <DeleteForeverIcon />
            )}
            
            {loading && (
                <CircularProgress size={24} />
            )}
        </IconButton>
    );
}