import React, { forwardRef, ReactNode } from 'react';
import { Box } from '@mui/material';

interface IPadBackGroundProps {
  children: ReactNode;
}

const IPadBackGround = forwardRef<HTMLDivElement, IPadBackGroundProps>(({ children }, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        width: '100%',
        // maxWidth: '568px',
        margin: '0',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '5.5%',
          left: '6%',
          width: '88%',
          height: '88%',
          overflow: 'auto',
          px: "5%",
          pt: "2%",
          pb: "20%",
          scrollbarWidth: "none",
        }}
      >
        <Box sx={{ pointerEvents: "none" }}>
          {children}
        </Box>
      </Box>
      <Box
        component="img"
        src="/images/ipad.png"
        sx={{
          width: '100%',
          // height: 'auto',
          display: 'block',
        }}
      />
    </Box>
  );
});

export default IPadBackGround;
