import { Checkbox, MenuItem, Menu, Button } from "@mui/material";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Option } from "components/_include/Filters/ErrorsSelect";

type MultiSelectDropdownProps = {
    id: string;
    loading: boolean;
    selectedOptions: string[];
    options: Option[];
    allOptionsSelected: boolean;
    isOpen: boolean;
    anchorEl: HTMLElement | null;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    handleClose: () => void;
    toggleAllOptions: (selected: boolean) => void;
    handleOptionsSelected: (error: string) => void;
    getMenuLabel: () => string;
};

/**
 * Dropdown select component with the possibility 
 * to select several options at the same time
 */
function MultiSelectDropdown(props: MultiSelectDropdownProps) {
    const {
        id,
        loading,
        selectedOptions,
        options,
        allOptionsSelected,
        isOpen,
        anchorEl,
        setAnchorEl,
        handleClose,
        toggleAllOptions,
        handleOptionsSelected,
        getMenuLabel
    } = props;

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.WASTES]);

    return (
        <>
            <Button
                id={`open-${id}`}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                aria-controls="select-menu"
                aria-haspopup="true"
                aria-expanded={isOpen ? 'true' : undefined}
                variant="contained"
                startIcon={<DeleteForeverIcon />}
                endIcon={<ArrowDropDownIcon />}
                color="white"
                disabled={loading}
                sx={{
                    fontWeight: 400,
                }}
            >
                {getMenuLabel()}
            </Button>
            <Menu
                id={id}
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'open-menu-button',
                }}
            >
                <MenuItem
                    key="all-errors"
                    onClick={() => toggleAllOptions(!allOptionsSelected)}
                    sx={{
                        py: .25,
                        pl: 1,
                    }}
                >
                    <Checkbox
                        color="info"
                        checked={allOptionsSelected}
                    />
                    {t("select_all", { ns: Namespace.ACTIONS })}
                </MenuItem>
                {options.map(option => (
                    <MenuItem
                        key={option.value}
                        onClick={(e) => handleOptionsSelected(option.value)}
                        sx={{
                            py: .25,
                            pl: 1,
                        }}
                    >
                        <Checkbox
                            color="info"
                            checked={selectedOptions.includes(option.value)}
                        />
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default MultiSelectDropdown;
