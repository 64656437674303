import { useMemo } from "react";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import SectionLoader from "components/_include/SectionLoader";
import FmdBadRoundedIcon from '@mui/icons-material/FmdBadRounded';
import { DANGER_COLORS } from "helpers/draw";
import DropdownMenu from "components/_include/DropdownMenu/DropdownMenu";
import { DangerLevel } from "constants/stats";
import MapDotMarker from "components/_include/Maps/MapDotMarker";
import { SearchParam } from "constants/urls";

export type Option = {
    label: string;
    value: string;
}

type OptionsSelectProps = {
    parentLoading: boolean;
}

const POSSIBLE_VALUES = Object.keys(DangerLevel);

function ErrorsCountSelect(props: OptionsSelectProps) {
    const { parentLoading } = props;

    const loading = parentLoading;

    const theme = useTheme();
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.COMMONS, Namespace.GLOSSARY, Namespace.MAPS,]);

    const [searchParams, setSearchParams] = useSearchParams();

    // selected errors count, "none" by default
    let selectedOption = searchParams.get(SearchParam.MIN_ERRORS_COUNT) ?? "";
    if (!selectedOption || !POSSIBLE_VALUES.includes(selectedOption)) {
        selectedOption = POSSIBLE_VALUES[0]; // "NONE"
    }

    const handleOptionSelected = (option: string) => {
        searchParams.set(SearchParam.MIN_ERRORS_COUNT, option);
        setSearchParams(searchParams);
    };

    /** 
     * Apply filter on loaded addresses when search param changes.
     * @todo Make this work, for some reason it's not using the latest list of batches 
     * when using in parallel of the errors classes filter
     */
    // useEffect(() => {
    //     const matchingIndex = POSSIBLE_VALUES.indexOf(selectedOption)!;
    //     const filteredData = BatchesController.filterAddressesByDangerLevel(batches, Object.values(DangerLevel)[matchingIndex]);
    //     dispatch(SortingMapActions.setMapPoints(filteredData));
    // }, [selectedOption]);

    const options: Option[] = useMemo(() => Object.entries(DangerLevel).map(([key, level]) => ({
        label: t(`errors_count_filter.${key}`, { ns: Namespace.MAPS }),
        value: key,
        startAdornment: (
            <Box component="span" mr={1}>
                <MapDotMarker
                    color={DANGER_COLORS[level]}
                    size={16}
                    zIndex={1}
                    inline
                />
            </Box>
        )
    })), [t]);

    return (
        <Box
            display="inline-block"
            position="relative"
            sx={{
                pointerEvents: "all",
            }}
        >
            {loading && (
                <SectionLoader size={theme.spacing(3)} />
            )}
            <DropdownMenu
                id="errors-count-menu"
                title={options[0].label} // by default all sorters are selected
                loading={loading}
                defaultValue={selectedOption}
                values={options}
                onChange={(v) => handleOptionSelected(v)}
                startIcon={<FmdBadRoundedIcon />}
            />
        </Box>
    );
}

export default ErrorsCountSelect;