import { useAppDispatch, useAppSelector, useDebouncedEffect, usePartnerID } from "store/hooks";
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import { useEffect, useState } from "react";
import PartnersController from "controllers/partners";
import { CollectionType } from "models/Collection";

export default function PartnerAccessCheckboxes() {
    const { t } = useTranslation([Namespace.PARTNERS]);

    const partnerID = usePartnerID();

    const partnerLoaded = useAppSelector(state => Boolean(state.partners.selected.data));
    const canAccessSettings = useAppSelector(state => state.partners.selected.data?.canAccessSettings);
    const canAccessWasteType = useAppSelector(state => state.partners.selected.data?.canAccessWasteType);
    const canAccessExplorer = useAppSelector(state => state.partners.selected.data?.canAccessExplorer);

    const [accessSettings, setAccessSettings] = useState({
        canAccessSettings: canAccessSettings || false,
        canAccessResidualWaste: canAccessWasteType?.residual_waste || false,
        canAccessExplorer: canAccessExplorer || false,
    });

    const dispatch = useAppDispatch();

    /** Sync state when partner data changes. */
    useEffect(() => {
        setAccessSettings({
            canAccessSettings: canAccessSettings || false,
            canAccessExplorer: canAccessExplorer || false,
            canAccessResidualWaste: canAccessWasteType?.residual_waste || false,
        });
    }, [canAccessSettings, canAccessExplorer, canAccessWasteType]);

    /** Debounced effect for updating access settings. */
    useDebouncedEffect(() => {
        if (partnerID && partnerLoaded) {
            dispatch(PartnersController.update(partnerID, {
                canAccessSettings: accessSettings.canAccessSettings,
                canAccessExplorer: accessSettings.canAccessExplorer,
                canAccessWasteType: { [CollectionType.RESIDUAL_WASTE]: accessSettings.canAccessResidualWaste },
            }));
        }
    }, [partnerID, partnerLoaded, accessSettings], 300);

    const handleChange = (field: keyof typeof accessSettings) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setAccessSettings((prev) => ({
            ...prev,
            [field]: event.target.checked,
        }));
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={accessSettings.canAccessSettings}
                        onChange={handleChange("canAccessSettings")}
                    />
                }
                label={t("can access settings", { ns: Namespace.PARTNERS })}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={accessSettings.canAccessExplorer}
                        onChange={handleChange("canAccessExplorer")}
                    />
                }
                label={t("can access explorer", { ns: Namespace.PARTNERS })}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={accessSettings.canAccessResidualWaste}
                        onChange={handleChange("canAccessResidualWaste")}
                    />
                }
                label={t("can access residual waste", { ns: Namespace.PARTNERS })}
            />
        </FormGroup>
    );
}
