import React from 'react';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions/transition';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type DialogWrapperProps = {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
};

const AddressReportDialogWrapper: React.FC<DialogWrapperProps> = ({ open, onClose, children }) => {
    return (
        <Dialog
            open={open}
            sx={{ padding: 0, }}
            keepMounted={false}
            PaperProps={{
                sx: {
                    display: "inline",
                    position: 'fixed',
                    bottom: "-15%",
                    right: "2%",
                    margin: 0,
                    maxHeight: '100%',
                    maxWidth: '600px',
                    width: '50%',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    overflow: 'visible',
                },
            }}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            {children}
        </Dialog>
    );
};

export default AddressReportDialogWrapper;
