import React from 'react';
import { Box, Hidden, IconButton, useTheme } from '@mui/material';
import Drawer from './Drawer';
import { DRAWER_WIDTH } from 'constants/theme';
import MenuIcon from '@mui/icons-material/Menu';

interface NavigatorProps {

}

function Navigator(props: NavigatorProps) {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const theme = useTheme();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box>
            <Box sx={{
                position: "absolute",
                left: (theme) => theme.spacing(2),
                top: (theme) => theme.spacing(1),
            }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    sx={{
                        [theme.breakpoints.up("md")]: {
                            display: "none",
                        }
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Box>
            <nav>
                <Hidden mdUp implementation="js">
                    <Drawer
                        PaperProps={{ style: { width: DRAWER_WIDTH }, elevation: 10, }}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    />
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer PaperProps={{ style: { width: DRAWER_WIDTH } }} />
                </Hidden>
            </nav>
        </Box>
    );
}

export default Navigator;
