import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MissionAddressesActions, selectAllMissionAddresses } from 'store/reducers/missions/addresses/list';
import { useParams } from 'react-router-dom';
import { Namespace } from 'locales/translations';
import { MissionStatus, ReportFormData, SensitizationAddresses } from 'models/Missions';
import { useTranslation } from 'react-i18next';
import AmbassadorsDropdown from '../AmbassadorsDropdown/AmbassadorsDropdown';
import AddressReport from '../AddressReport/AddressReport';
import { TrashCount } from 'constants/trash';
import FichaDataGrid from '../../DataTable/FichaDataGrid';
import { SortingMapActions } from 'store/reducers/batches/sorting_map';
import MissionAddressesMethods from 'controllers/sensitization_addresses';
import MissionAddressStatusChip from './MissionAddressStatusChip';
import MissionAddressActionButton from './MissionAddressActionButton';

type StatusProps = Pick<SensitizationAddresses, "visited"> & Pick<NonNullable<SensitizationAddresses["report"]>, "inhabitantsSpoken">;

export type AddressRowData = {
    ID: string;
    addressKey: string;
    house: string | undefined;
    street: string;
    city: string;
    postalCode: string;
    error: Partial<TrashCount>;
    errorDetail: Partial<TrashCount>;
    errorsCount: number;
    lat: number;
    lng: number;
    batchesCount: number;
    status: StatusProps;
    ambassadors: string;
    addressReport?: ReportFormData;
};

/**
 * Table listing all the addresses that have been added to the selected mission.
 */
function MissionAddressesTable() {
    const dispatch = useAppDispatch();
    const missionAddresses = useAppSelector(selectAllMissionAddresses);
    const selectedRowsIds = useAppSelector((state) => state.missions.missionAddresses.selectedRowsIds);
    const missionStatus = useAppSelector(state => state.missions.selectedMission.data?.status);
    const { t } = useTranslation([Namespace.MISSIONS]);

    const { missionID } = useParams();

    const rows = useMemo(() => {
        return missionAddresses.map((missionAddress) => {
            const addressData: AddressRowData = {
                ID: missionAddress.ID,
                addressKey: missionAddress.addressKey,
                house: missionAddress.address.houseNumber,
                street: missionAddress.address.street,
                city: missionAddress.address.city,
                postalCode: missionAddress.address.postalCode,
                error: missionAddress.errors,
                errorDetail: missionAddress.errors,
                errorsCount: missionAddress.errorsCount,
                lat: missionAddress.lat,
                lng: missionAddress.lng,
                batchesCount: missionAddress.batchesCount,
                status: { visited: missionAddress.visited, inhabitantsSpoken: missionAddress.report?.inhabitantsSpoken !== false },
                ambassadors: missionAddress.assigned?.name ?? t('not_assigned', { ns: Namespace.MISSIONS }),
                addressReport: missionAddress.report,
            };
            return addressData;
        });
    }, [missionAddresses, t]);

    const columns: GridColDef[] = useMemo(() => [
        { field: 'house', headerName: t('house', { ns: Namespace.MISSIONS }) },
        { field: 'street', headerName: t('street', { ns: Namespace.MISSIONS }), minWidth: 150 },
        { field: 'city', headerName: t('city', { ns: Namespace.MISSIONS }) },
        {
            field: 'status',
            headerName: t('mission_address_status', { ns: Namespace.MISSIONS }),
            minWidth: 110,
            renderCell: (params: GridRenderCellParams<AddressRowData>) => {
                const { visited, inhabitantsSpoken } = params.value;
                const addressStatus = MissionAddressesMethods.getAddressStatus(true, false, visited, inhabitantsSpoken);
                return <MissionAddressStatusChip status={addressStatus} />
            },
        },
        {
            field: 'errorsCount',
            headerName: t('errors_count', { ns: Namespace.MISSIONS }),
            type: 'number',
            maxWidth: 100,
        },
        {
            field: 'errorDetail',
            headerName: t('error_detail', { ns: Namespace.MISSIONS }),
            minWidth: 155,
            renderCell: (params: GridRenderCellParams<Partial<TrashCount>, AddressRowData>) => {
                const errors = params.value || {};

                if (typeof errors !== 'object') {
                    return;
                }

                return (
                    <Typography variant="body2">
                        {Object.entries(errors)
                            .filter(([_, count]) => count > 0)
                            .map(([trashType, count]) =>
                                t(`${trashType}_count`, { ns: Namespace.WASTES, context: 'small', count: count })
                            ).join(', ')}
                    </Typography>
                );
            },
        },
        { field: 'ambassadors', headerName: t('ambassadors', { ns: Namespace.MISSIONS }), flex: 1 },
        {
            field: 'action',
            headerName: t('action', { ns: Namespace.MISSIONS }),
            renderCell: (params: GridRenderCellParams<AddressRowData>) => (
                <MissionAddressActionButton 
                    address={params.row}
                    />
            ),
        },
    ], [t]);

    /** Dispatch action to highlight the hovered address on the map. */
    const handleRowMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        const rowId = event.currentTarget.getAttribute('data-id');
        const hoveredRow = rows.find((r) => r.ID === rowId);
        dispatch(SortingMapActions.hoverAddressKey(hoveredRow?.addressKey ?? null));
    };

    /** Dispatch action to stop highlighting the hovered address on the map. */
    const handleRowMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
        const rowId = event.currentTarget.getAttribute('data-id');
        const hoveredRow = rows.find((r) => r.ID === rowId);
        dispatch(SortingMapActions.leavePlaceID(hoveredRow?.addressKey ?? null));
    };

    return (
        <Box>
            <Box display="flex" marginBottom={2} justifyContent="space-between">
                <Typography variant="h3">
                    {t('addresses_added', { ns: Namespace.MISSIONS })}
                </Typography>
                {missionID && missionStatus !== MissionStatus.COMPLETED && (
                    <AmbassadorsDropdown
                        disabled={selectedRowsIds.length === 0}
                        selectedAddressIds={selectedRowsIds}
                        missionID={missionID}
                    />
                )}
            </Box>
            <FichaDataGrid
                rows={rows}
                columns={columns}
                checkboxSelection={missionStatus !== MissionStatus.COMPLETED}
                onRowSelectionModelChange={(newSelection) => {
                    dispatch(MissionAddressesActions.setSelectedRowsIds(newSelection as string[]))
                }
                }
                slotProps={{
                    row: {
                        onMouseEnter: handleRowMouseEnter,
                        onMouseLeave: handleRowMouseLeave,
                    },
                }}
                getRowId={(row) => row.ID}
                maxHeight="85vh"
            />
            
            <AddressReport />
        </Box>
    );
}

export default MissionAddressesTable;
