import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useCanWrite, usePartnerID } from 'store/hooks';
import MissionsMethods from 'controllers/mission';
import { Namespace } from 'locales/translations';
import { MissionDbData, MissionStatus } from 'models/Missions';
import { DatePickerModal } from 'react-rainbow-components';
import { Timestamp } from 'firebase/firestore';
import { UNIX_FORMAT } from 'constants/dates';
import ConfirmMarkAsDoneDialog from './ConfirmMarkAsDoneDialog';
import ConfirmDeleteMissionDialog from './ConfirmDeleteMissionDialog';

/**
 * A header component for mission details. 
 * Displays the mission's deadline and allows editing it if the mission is not completed.
 * Also provides an option to mark the mission as done, showing a confirmation dialog.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const MissionDetailHeader: React.FC = () => {
    const { t, i18n } = useTranslation([Namespace.ACTIONS]);
    const missionID = useAppSelector(state => state.missions.selectedMission.data?.ID);
    const missionStatus = useAppSelector(state => state.missions.selectedMission.data?.status);
    const deadline = useAppSelector(state => state.missions.selectedMission.data?.deadline);
    const dispatch = useAppDispatch();
    const partnerID = usePartnerID();
    const canDelete = useCanWrite(partnerID);

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    /**
     * Handles the event when a new deadline date is selected.
     *
     * @param {Date} value - The newly selected deadline date.
     * @returns {Promise<void>}
     */
    const onDeadlineDateChange = async (value: Date) => {
        if (!partnerID || !missionID) return;
        const updatedMissionData: Partial<MissionDbData> = { deadline: Timestamp.fromDate(value) };
        await dispatch(MissionsMethods.update(partnerID, missionID, updatedMissionData));
        setIsDatePickerOpen(false);
    };

    return (
        <Box display="flex" justifyContent="space-between" pb={3} alignItems="center" justifyItems="center">
            <Box display="flex" alignItems="center" py={2}>
                <Typography fontSize="18px" fontWeight="900" sx={{ color: 'rgba(0, 0, 0, 0.70)' }}>
                    {t('deadline', { ns: Namespace.MISSIONS })}: {moment(deadline, UNIX_FORMAT).format('LL')}
                </Typography>
                {missionStatus !== MissionStatus.COMPLETED && (
                    <IconButton
                        size="small"
                        onClick={() => setIsDatePickerOpen(true)}
                        aria-label={t('edit_deadline', { ns: Namespace.ACTIONS })}
                        sx={{ marginLeft: 1 }}
                    >
                        <EditIcon color='secondary' fontSize="small" />
                    </IconButton>
                )}
            </Box>

            <DatePickerModal
                isOpen={isDatePickerOpen}
                onRequestClose={() => setIsDatePickerOpen(false)}
                onChange={onDeadlineDateChange}
                value={deadline ? moment(deadline, UNIX_FORMAT).toDate() : undefined}
                minDate={new Date()}
                selectionType="single"
                locale={i18n.language}
            />

            <Box display="flex" alignItems="center" flexWrap={'wrap'} gap={2}>
                {canDelete && (
                    <ConfirmDeleteMissionDialog />
                )
                }
                {missionStatus !== MissionStatus.COMPLETED && (
                    <ConfirmMarkAsDoneDialog />
                )}
            </Box>
        </Box>
    );
};

export default MissionDetailHeader;
