import { DataGrid, GridColDef, GridRenderCellParams, GridRowModel, GridRowParams, GridToolbar } from "@mui/x-data-grid";
import { formatBinsCounts } from "components/_include/BinsCountsLabel";
import { getDisplayedErrors, getErrorsCount } from "helpers/trash";
import { Locale, Namespace } from "locales/translations";
import { upperFirst } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useSortingRules } from "store/hooks";
import { selectAllBatches } from "store/reducers/batches/list";
import { frFR } from "@mui/x-data-grid/locales";
import { TrashCount } from "constants/trash";
import Batch from "models/Batch";
import ActionButton from "components/_include/Buttons/ActionButton";
import { Link } from "react-router-dom";
import { Section } from "constants/pages";
import { TFunction } from "i18next";

enum Column {
    DATE = "date",
    HOUSE_NUMBER = "house_number",
    STREET = "street",
    CITY = "city",
    BINS = "bin",
    RFID = "rfid",
    ERRORS = "error",
    ERRORS_DETAILS = "errors_details",
    ACTIONS = "actions"
}

type BatchRowDict = { [key in Column]: any } & {
    id: string;
    partnerID: string;
};

const COLUMNS_CONFIG: GridColDef[] = [
    {
        field: Column.DATE,
        minWidth: 180,
        type: 'dateTime',
    },
    {
        field: Column.HOUSE_NUMBER,
        minWidth: 80,
        type: 'string',
    },
    {
        field: Column.STREET,
        minWidth: 160,
        flex: 2,
        type: 'string',
    },
    {
        field: Column.CITY,
        minWidth: 120,
        flex: 1,
        type: 'string',
    },
    {
        field: Column.BINS,
        minWidth: 200,
        flex: 2,
        type: 'string',
    },
    {
        field: Column.RFID,
        minWidth: 120,
        flex: 1,
        type: 'string',
    },
    {
        field: Column.ERRORS,
        minWidth: 80,
        type: 'number',
    },
    {
        field: Column.ERRORS_DETAILS,
        minWidth: 200,
        flex: 2,
        type: 'string',
    },
    {
        field: Column.ACTIONS,
        minWidth: 120,
        type: 'actions',
    },
];

const getColumn = (column: GridColDef, context: { t: TFunction }) => {
    const { t } = context;

    const c: GridColDef = {
        ...column,
        resizable: true,
        headerName: upperFirst(t(column.field, {
            ns: [Namespace.COMMONS, Namespace.GLOSSARY],
            count: 2, // make headers plural
        })),
        ...(column.field === Column.ERRORS_DETAILS && {
            renderCell: (params: GridRenderCellParams<Batch, Partial<TrashCount>>) => {
                const errors = params.value;

                return (
                    <>
                        {Object.entries(errors ?? {})
                            .filter(([_, count]) => count > 0)
                            .map(([trashType, count]) =>
                                t(`${trashType}_count`, { ns: Namespace.WASTES, context: 'small', count: count })
                            ).join(', ')}
                    </>
                );
            },
        }),
        ...(column.field === Column.ACTIONS && {
            getActions: (params: GridRowParams<BatchRowDict>) => [
                <Link
                    key="view-image"
                    target="_blank"
                    to={{
                        pathname: `/${Section.SINGLE_IMAGE}`,
                        search: `?partnerID=${params.row.partnerID}&imageID=${params.id}`,
                    }}
                >
                    <ActionButton
                        color="secondary"
                        size="compact"
                    >
                        {t("view", { ns: Namespace.ACTIONS })}
                    </ActionButton>
                </Link>
            ],
        }),
    };
    return c;
}

export function ExplorerDataGrid() {

    const { t, i18n: { language } } = useTranslation([Namespace.ACTIONS, Namespace.COMMONS, Namespace.GLOSSARY]);

    const { errorsClasses, mergingMapping } = useSortingRules();

    const batches = useAppSelector(selectAllBatches);
console.debug("batch", batches[0]);
    const columns = useMemo(() => COLUMNS_CONFIG.map(column => getColumn(column, { t })), [t]);

    const rows = useMemo(() => batches.map(batch => ({
        id: batch.ID,
        partnerID: batch.partnerID,
        [Column.DATE]: moment(batch.timestamp).toDate(),
        [Column.HOUSE_NUMBER]: batch.address?.houseNumber,
        [Column.STREET]: batch.address?.street,
        [Column.CITY]: batch.address?.city,
        [Column.BINS]: formatBinsCounts(t, batch.binsCounts ?? { bin: 1 }),
        [Column.RFID]: batch.binsRFIDs,
        [Column.ERRORS]: getErrorsCount(errorsClasses, batch.results),
        [Column.ERRORS_DETAILS]: getDisplayedErrors(errorsClasses, mergingMapping, batch.results),
    })), [t, batches, errorsClasses]);

    return (
        <DataGrid
            columns={columns}
            rows={rows}
            sx={{
                bgcolor: "#fff",
                "& .MuiDataGrid-columnHeader": {
                    bgcolor: "#fff",
                },
                "& .MuiDataGrid-columnSeparator ": {
                    visibility: "visible"
                }
            }}
            ignoreDiacritics
            disableColumnSelector
            disableColumnFilter
            slots={{ toolbar: GridToolbar }}
            slotProps={{
                columnHeaders: {
                    color: "#f00",
                    style: { background: "#f00" }
                },
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                        placeholder: t("quick_search", { ns: Namespace.ACTIONS }),
                    }
                },
            }}
            // translate to French if selected
            {...(language === Locale.FRENCH && { localeText: frFR.components.MuiDataGrid.defaultProps.localeText })}
        />
    )
}