import ActionButton from "components/_include/Buttons/ActionButton";
import { useSearchParams } from "react-router-dom";
import { DatesRangeType, getStartAndEndFromURLParams, isSingleDay } from 'helpers/dates';
import { Timestamp } from '@firebase/firestore';
import { useAppSelector, useSortingRules, usePartnerID, useAppDispatch } from 'store/hooks';
import BatchesController from 'controllers/batches';
import { TrashType } from 'constants/trash';
import { SearchParam } from 'constants/urls';
import { useMemo } from "react";
import moment from "moment";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";

type ButtonProps = {
    loadingLimit: number;
}

export function SearchBatchesButton({ loadingLimit }: ButtonProps) {
    const { t } = useTranslation([Namespace.ACTIONS]);
    
    const { displayedErrors } = useSortingRules();

    const loading = useAppSelector(state => state.batches.list.loading);

    const [searchParams] = useSearchParams();
    const truckID = searchParams.get(SearchParam.TRUCK_ID) ?? undefined;
    const areaID = searchParams.get(SearchParam.AREA_ID) ?? undefined;
    const routeID = searchParams.get(SearchParam.ROUTE_ID) ?? undefined;
    const dateSelect = searchParams.get(SearchParam.DATES_RANGE_TYPE_SELECT);
    const startDate = searchParams.get(SearchParam.START_DATE);
    const endDate = searchParams.get(SearchParam.END_DATE);
    const startTime = searchParams.get(SearchParam.START_TIME);
    const endTime = searchParams.get(SearchParam.END_TIME);
    const errors = searchParams.getAll(SearchParam.SELECTED_ERRORS)
        .filter(e => displayedErrors
            .includes(e as TrashType)) as TrashType[];

    const partnerID = usePartnerID();

    const wasteStream = useAppSelector(state => state.sortingRules.selectedWasteStream);
    
    const dispatch = useAppDispatch();

    /**
     * Is only a single day selected? 
     * (then we display the Times range select)
     */
    const singleDaySelected = useMemo(() => {
        if (!dateSelect) return false;
        return isSingleDay(dateSelect as DatesRangeType, moment(startDate), moment(endDate));
    }, [dateSelect, startDate, endDate]);

    const getBatches = () => {
        if (!partnerID || !wasteStream) return;

        let filters: Parameters<typeof BatchesController.list>[1] = [
            { fieldPath: "collectionType", opStr: "==", value: wasteStream, },
            { fieldPath: "display", opStr: "==", value: true }, 
            { fieldPath: "verified", opStr: "==", value: true },
        ];

        if (truckID) {
            filters.push({
                fieldPath: "truckID",
                opStr: "==",
                value: truckID?.toString(),
            });
        }

        if (areaID) {
            filters.push({
                fieldPath: "areasIDs",
                opStr: "array-contains",
                value: areaID?.toString(),
            });
        }

        if (routeID) {
            filters.push({
                fieldPath: "routeID",
                opStr: "==",
                value: routeID?.toString(),
            });
        }

        if (dateSelect) { // dates filter
            const { start, end } = getStartAndEndFromURLParams(dateSelect as DatesRangeType, startDate, endDate, singleDaySelected ? startTime : undefined, singleDaySelected ? endTime : undefined)

            if (start) {
                filters.push({
                    fieldPath: "timestamp",
                    opStr: ">=",
                    value: Timestamp.fromDate(start.toDate()),
                });
            }

            if (end) {
                filters.push({
                    fieldPath: "timestamp",
                    opStr: "<=",
                    value: Timestamp.fromDate(end.toDate()),
                });
            }
        }

        dispatch(BatchesController.list(partnerID, filters, errors, { fieldPath: "timestamp", directionStr: "asc" }, loadingLimit));
    };

    return (
        <ActionButton
            color="secondary"
            loading={loading}
            disabled={loading}
            onClick={getBatches}
        >
            {t("search", { ns: Namespace.ACTIONS })}
        </ActionButton>
    )
}